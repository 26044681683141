import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme/theme'
import { RecoilRoot } from 'recoil'
import DebugObserver from './components/DebugObserver'


// @TODO - Remove the below temporary redirect after NEARCon 2022
const campaignId = window.location.href.split('://')[1].split('/')[1]
console.log('campaignId', campaignId)
if (campaignId === 'fpMgcyZ2GxmZaqQtZUEht') {
    console.log('Redirecting to updated landing URL: ', `${window.location.origin}/_NY2hWTqf7EssEopJv01f`)
    window.location.href = `${window.location.origin}/_NY2hWTqf7EssEopJv01f`
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <DebugObserver />
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)
