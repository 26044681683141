import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SonarService } from '../services/SonarService'
import constants from '../utils/constants'
import Loading from '../components/Loading'
import { RouteComponentProps, useHistory } from 'react-router-dom'

type RedirectType = 'twitter' | 'tiktok'

const Redirect = (props: RouteComponentProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  console.log('props in Redirect: ', props)
  const type = props.location.pathname.split('/redirect/')[1] as RedirectType
  console.log('type: ', type)

  const [error, setError] = useState<string>('')

  const redirect = async (type: RedirectType) => {
    if (type === 'tiktok') {
      const params = new URLSearchParams(window.location.search)
      const code = params.get('code')
      const state = params.get('state')
      const error = params.get('error')
      const campaignId = params.get('campaignId')
      if (!code || !state || !campaignId || error) {
        setError(t('errors.checkUrl'))
        return
      }
      const campaign = (await SonarService.getCampaign(campaignId as string, constants.SONAR_API_KEY)) as Campaign
      const response = await SonarService.tiktokCallback(
        code as string,
        state as string,
        campaign.id,
        process.env.REACT_APP_SONAR_API_KEY as string
      )
      if (!response.claimLink) {
        setError(t('errors.tryAgain'))
        if (response.headers.get('Content-Type') === 'application/json') {
          console.error('error: ', await response.json())
        }
      } else {
        const { claimLink } = response as { claimLink: string }
        window.location.href = claimLink
      }
    } else if (type === 'twitter') {
      const campaignId = localStorage.getItem('campaignId')
      const fingerprint = localStorage.getItem('fingerprint')
      const queryParams = new URLSearchParams(window.location.search)
      const oauthToken = queryParams.get('oauth_token')
      const oauthVerifier = queryParams.get('oauth_verifier')
      if (!campaignId || !fingerprint || !oauthToken || !oauthVerifier) {
        setError(t('errors.checkUrl'))
        return
      }
      localStorage.removeItem('campaignId')
      localStorage.removeItem('fingerprint')
      let res = (await SonarService.twitterCallback(
        campaignId,
        fingerprint,
        oauthToken,
        oauthVerifier,
        process.env.REACT_APP_SONAR_API_KEY as string
      )) as unknown as { user: TwitterUser; claimLink: string }
      localStorage.setItem('twUserName', res.user.userName)
      history.push(`/${campaignId}`)
    } else {
      setError(t('errors.checkUrl'))
    }
  }

  useEffect(() => {
    redirect(type)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="App" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      {error ? (
        <div className="centered" style={{ margin: '1em' }}>
          {error}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default Redirect
