import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { nearState, accountIdState, claimIdFromUrlState, linkdropResIdState } from '../../../store'
import constants from '../../../utils/constants'
import { SonarService } from '../../../services/SonarService'
import ConnectYourWallet from '../../Claim/ConnectYourWallet'
import { LS_NEAR_ID } from '../../Claim'
import { initNear, signIn } from '../../../services/near'
import { WalletConnection } from 'near-api-js'

/* STEP 1: User sets NEAR account ID (we will mint NFT to this address)
  - Connect Wallet      
      wallet redirect:                /{campaignId}?accountId={accountId}    
      local storage    
  - Create Wallet
      POST /order/reserve-claim-id    return {linkdropUrl, reservationIdForClaimId}
      wallet redirect                 /{campaignId}/{PREFIX}{reservationIdForClaimId}?accountId={accountId}
      local storage
  - NEAR Local Storage                /{campaignId}
      local storage
*/
function AccountStep({ campaign }: { campaign: Campaign }) {
  const { t } = useTranslation()
  const [near, setNear] = useRecoilState(nearState)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [claimIdFromUrl] = useRecoilState(claimIdFromUrlState) // used for linkdrop res id

  const setAccountId = useSetRecoilState(accountIdState)
  const setLinkdropResId = useSetRecoilState(linkdropResIdState)

  const [accoundIdError, setAccountIdError] = useState<string>('')

  useEffect(() => {
    async function parseUrl() {
      // Extract NEAR Account ID
      const accountIdFromURL =
        window.location.href.split('?accountId=')[1]?.split('&')[0] ||
        window.location.href.split('?account_id=')[1]?.split('&')[0]

      if (accountIdFromURL) {
        // For users who redirected from NEAR wallet site (linkdrop or sign-in)
        setAccountId(accountIdFromURL)
      } else {
        // For users who may already be signed-in via NEAR API JS
        const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
        setAccountId(wallet.getAccountId()) // may be undefined, in which case user will need to create or sign-in to a wallet
      }

      // If Linkdrop, get linkdrop reservation id
      if (claimIdFromUrl) {
        setLinkdropResId(claimIdFromUrl.split(constants.LINKDROP_RES_ID_PREFIX)[1])
      }
    }
    parseUrl()
  }, [near, setNear, claimIdFromUrl, setAccountId, setLinkdropResId])

  async function handleCreateWallet() {
    setIsLoading(true)
    let res

    // This step is really just to create a prefunded implicit account on NEAR
    // Since a claimId is required for a linkdrop, we request one on the backend and keep it there
    // Once the user pays the credit card, we mint the NFT with that claimId to the specified linkdrop address

    try {
      res = await SonarService.reserveClaimIdAndCreateLinkdrop(
        campaign.id,
        window.location.href + '/' + constants.LINKDROP_RES_ID_PREFIX,
        constants.SONAR_API_KEY
      )
      const { linkdrop, claimlinkReservationId } = res
      console.log('linkdrop', linkdrop)
      console.log('claimlinkReservationId', claimlinkReservationId)
      // Proceed to Linkdrop redirect
    } catch (e) {
      console.error('Error creating wallet:', e)
      if (typeof e === 'string') setAccountIdError(e)
    }
    setIsLoading(false)

    if (!res.linkDrop) {
      setAccountIdError(t('errors.nearAccountCreation'))
      return
    }

    // 1. We redirect user to the NEAR wallet
    // 2. there, the user will bind a human-readable accountID to the implicit account
    // 3. the wallet will redirect back here with searchParams containing the accountId
    window.location = res.linkDrop
  }

  async function handleConnectWallet() {
    const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
    try {
      await signIn(wallet, `/${campaign.id}`)
      localStorage.setItem(LS_NEAR_ID, wallet.getAccountId())
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ConnectYourWallet
      handleCreateWallet={handleCreateWallet}
      handleConnectWallet={handleConnectWallet}
      error={accoundIdError}
      isLoading={isLoading}
    />
  )
}

export default AccountStep
