import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { SonarService } from '../services/SonarService'
import constants from '../utils/constants'
import { launchConfetti } from '../utils/claimlinks'
import { useRecoilState } from 'recoil'
import { nearState } from '../store'
import { WalletConnection } from 'near-api-js'
import { initNear, signIn } from '../services/near'
import ConnectYourWallet from './Claim/ConnectYourWallet'
import ClaimNFT from './Claim/ClaimNFT'
import Claimed from './Claim/Claimed'

export const LS_NEAR_ID = 'NEAR_ID'

interface ClaimProps {
  campaign: Campaign
  claimId: string
  disableLinkdrop?: boolean
}

const Claim = ({ campaign, claimId, disableLinkdrop = false }: ClaimProps) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [near, setNear] = useRecoilState(nearState)
  const [accountId, setAccountId] = useState<string | null>(null)
  const [accountIdFromURL, setAccountIdFromURL] = useState<string | null>(null)
  const [txHash, setTxHash] = useState<string | undefined>() // TODO will tx hash always be successful?

  useEffect(() => {
    async function checkForAccountId() {
      const aIdFromURL =
        window.location.href.split('?accountId=')[1]?.split('&')[0] ||
        window.location.href.split('?account_id=')[1]?.split('&')[0]

      if (aIdFromURL) {
        // For users who needed to create or sign-in to a NEAR wallet
        // accountId will be in URL via a Linkdrop or Sign-In redirect
        setAccountId(aIdFromURL)
        setAccountIdFromURL(aIdFromURL)
      } else {
        // For users who may already be signed-in to a NEAR Wallet
        const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
        setAccountId(wallet.getAccountId()) // may be undefined, in which case user will need to create or sign-in to a wallet
      }
    }
    checkForAccountId()
  }, [near, setNear])

  useEffect(() => {
    handleClaimNFT()
    // eslint-disable-next-line
  }, [accountIdFromURL])

  async function handleCreateWallet() {
    setIsLoading(true)
    let res

    // A prefunded implicit account will be created on NEAR
    try {
      const redirectURL = `${window.location.href}/${claimId}` // include claimId to maintain state in the workflow
      res = await SonarService.createLinkdrop(claimId, redirectURL, constants.SONAR_API_KEY)
    } catch (e) {
      console.error('Error creating wallet:', e)
      if (typeof e === 'string') setError(e)
    }
    setIsLoading(false)

    if (!res.linkDrop) {
      setError(t('errors.nearAccountCreation'))
      return
    }

    // 1. We redirect user to the NEAR wallet
    // 2. there, the user will bind a human-readable accountID to the implicit account
    // 3. the wallet will redirect back here with searchParams containing the accountId and claimId
    window.location = res.linkDrop
  }

  async function handleConnectWallet() {
    const wallet = (near.wallet as WalletConnection) || (await initNear(setNear))
    try {
      await signIn(wallet, `/${campaign.id}/${claimId}`)
      localStorage.setItem(LS_NEAR_ID, wallet.getAccountId())
    } catch (e) {
      console.error(e)
    }
  }

  async function handleClaimNFT() {
    if (!claimId || !accountId) return

    setIsLoading(true)

    try {
      const [contractId, title] = campaign.seriesId.split('/')
      const res = await SonarService.claimNFT(
        claimId,
        contractId,
        title,
        accountId,
        constants.SONAR_API_KEY,
        campaign.id
      )

      if (res.nft?.status.SuccessValue) {
        setTxHash(res.nft.transaction.hash)
        launchConfetti()
      } else {
        if (res.status === 403) setError(t('errors.alreadyClaimed'))
        // Why is reclaiming the same code resulting in 404 vs 403?
        if (res.status === 404) setError(t('errors.nftNotFound'))
        if (res.error?.toLowerCase().includes('no claim')) setError(t('errors.noClaim'))
      }
    } catch (e) {
      console.error('Error claiming NFT:', e)
      if (typeof e === 'string') setError(e)
    }
    setIsLoading(false)
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{
        pb: {
          xs: 12,
          sm: 0,
        },
      }}
    >
      {!accountId ? (
        <ConnectYourWallet
          handleCreateWallet={handleCreateWallet}
          handleConnectWallet={handleConnectWallet}
          error={error}
          isLoading={isLoading}
          disableLinkdrop={disableLinkdrop}
        />
      ) : !txHash ? (
        <ClaimNFT
          handleClaimNFT={handleClaimNFT}
          handleConnectWallet={handleConnectWallet}
          error={error}
          isLoading={isLoading}
          accountId={accountId}
        />
      ) : (
        <Claimed txHash={txHash} />
      )}
    </Grid>
  )
}

export default Claim
