import { SetterOrUpdater } from 'recoil'
import { PaymentAuthorizationState } from '../../models/payment'

const setIsAuthorizing = (
  props: Pick<PaymentAuthorizationState, 'isAuthorizing'> & Partial<Pick<PaymentAuthorizationState, 'walletId'>>,
  set: SetterOrUpdater<PaymentAuthorizationState>
) => set((state) => ({ ...state, ...props }))

const setFormError = (value: string, set: SetterOrUpdater<PaymentAuthorizationState>) =>
  set((state) => ({ ...state, formError: value }))

export { setIsAuthorizing, setFormError }
