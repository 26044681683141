import { atom } from 'recoil'
import { PaymentProcessor } from '../enum'
import { NearState } from './models/near'
import { PaymentAuthorizationState, PaymentClaimState, PaymentFormState, PaymentStepState } from './models/payment'

/**
 * The distribution campaign for this NFT
 */
const campaignState = atom<Campaign | undefined>({
  key: 'campaign',
  default: undefined,
})

/**
 * The NFT data from the blockchain
 */
const seriesState = atom<Series | undefined>({
  key: 'series',
  default: undefined,
})

/**
 * If NFT has remaining mints
 */
const isMintableState = atom<boolean>({
  key: 'isMintable',
  default: true,
})

const loadingState = atom({
  key: 'loading',
  default: true,
})

const errorState = atom({
  key: 'error',
  default: '',
})

// ================================================================
// Claim Workflow
// ================================================================

/**
 * If user is signed into NEAR wallet (necessary to mint)
 */
const nearState = atom<NearState>({
  key: 'near',
  default: {
    wallet: null,
  },
  dangerouslyAllowMutability: true,
})

/**
 * If this appears in URL, user can mint an NFT
 */
const claimIdFromUrlState = atom<string | undefined>({
  key: 'claimIdFromUrl',
  default: undefined,
})

// ================================================================
// Fiat Payments - inverted Claim Workflow
// ================================================================

/**
 * Tracks the user's progress through the fiat payments workflow
 * 0. Account Step
 * 1. Processor Step
 *    i. Submit Order
 *    ii. Authorize Order
 * 2. Receipt Step
 */
const paymentStepState = atom<PaymentStepState>({
  key: 'paymentStep',
  default: {
    step: 0,
    price: 0,
    satoriFee: 0,
    wyreFee: 0,
    totalPrice: 0,
    isAccountId: false,
    isCardSubmitted: false,
    orderId: undefined,
    isAuthorized: false,
  },
})

/**
 * If user has specified a NEAR account to mint to
 */
const accountIdState = atom<string | undefined>({
  key: 'accountId',
  default: undefined,
})

/**
 * If user has specified a NEAR account to mint to (via Linkdrop)
 */
const linkdropResIdState = atom<string | undefined>({
  key: 'linkdropResId',
  default: undefined,
})

let paymentFormInitialState =
  process.env.NODE_ENV === 'production'
    ? {
        key: 'paymentForm',
        default: {
          processor: PaymentProcessor.Spreedly,
          givenName: '',
          givenNameError: undefined,
          isGivenNameValid: false,
          familyName: '',
          familyNameError: undefined,
          isFamilyNameValid: false,
          phone: '',
          phoneError: undefined,
          isPhoneValid: false,
          email: '',
          emailError: undefined,
          isEmailValid: false,
          street: '',
          streetError: undefined,
          isStreetValid: false,
          city: '',
          cityError: undefined,
          isCityValid: false,
          state: '',
          stateError: undefined,
          isStateValid: false,
          postalCode: '',
          postalCodeError: undefined,
          isPostalCodeValid: false,
          country: {
            key: 'US',
            label: 'United States',
            phone: '1',
            suggested: true,
          },
          countryError: undefined,
          expiryMonth: '',
          expiryYear: '',
          paymentToken: undefined,
          isAgreed: false,
          isFormValid: false,
          isSubmitting: false,
          formError: undefined,
        },
      }
    : {
        key: 'paymentForm',
        default: {
          processor: PaymentProcessor.Spreedly,
          givenName: 'Joe',
          givenNameError: undefined,
          isGivenNameValid: true,
          familyName: 'Jones',
          familyNameError: undefined,
          isFamilyNameValid: true,
          phone: '9193313313',
          phoneError: undefined,
          isPhoneValid: true,
          email: 'user+3ds@example.com',
          emailError: undefined,
          isEmailValid: true,
          street: '333 Lane Road',
          streetError: undefined,
          isStreetValid: true,
          city: 'Wanaque',
          cityError: undefined,
          isCityValid: true,
          state: 'NJ',
          stateError: undefined,
          isStateValid: true,
          postalCode: '31331',
          postalCodeError: undefined,
          isPostalCodeValid: true,
          country: {
            key: 'US',
            label: 'United States',
            phone: '1',
            suggested: true,
          },
          countryError: undefined,
          expiryMonth: '',
          expiryYear: '',
          paymentToken: undefined,
          isAgreed: false,
          isFormValid: false,
          isSubmitting: false,
          formError: undefined,
        },
      }

/**
 * Tracks the state of the Order Form in the fiat payments workflow
 */
const paymentFormState = atom<PaymentFormState>(paymentFormInitialState)

/**
 * Tracks the state of the Order Authorization in the fiat payments workflow
 */
const paymentAuthorizationState = atom<PaymentAuthorizationState>({
  key: 'paymentAuthorization',
  default: {
    isAuthorizing: false,
    formError: undefined,
    walletId: undefined,
  },
})

/**
 * Tracks the state of the Claim in the fiat payments workflow
 */
const paymentClaimState = atom<PaymentClaimState>({
  key: 'paymentClaim',
  default: {
    paymentMethodName: undefined,
    sourceAmount: undefined,
    status: undefined,
    transferId: undefined,
    txHash: undefined,
  },
})

export {
  campaignState,
  seriesState,
  isMintableState,
  loadingState,
  errorState,
  nearState,
  claimIdFromUrlState,
  paymentStepState,
  accountIdState,
  linkdropResIdState,
  paymentFormState,
  paymentAuthorizationState,
  paymentClaimState,
}
