import { Box, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { accountIdState, claimIdFromUrlState, paymentStepState, seriesState } from '../../store'
import { useRecoilState } from 'recoil'
import { setFees, setStep } from '../../store/actions/payment/paymentStep'
import AccountStep from './containers/AccountStep'
import ProcessorStep from './containers/ProcessorStep'
import ReceiptStep from './containers/ReceiptStep'
import NFTHeader from '../NFTHeader'

function PaymentStepper({ campaign, title }: { campaign: Campaign; title: string }) {
  const { t } = useTranslation()
  const [claimIdFromUrl] = useRecoilState(claimIdFromUrlState) // used for linkdrop res id
  const [series] = useRecoilState(seriesState)
  const [accountId] = useRecoilState(accountIdState)
  const [{ step, isCardSubmitted, isAuthorized }, setPaymentStepState] = useRecoilState(paymentStepState)

  useEffect(() => {
    switch (step) {
      case 0:
        if (accountId) setStep(1, setPaymentStepState)
        break
      case 1:
        if (isAuthorized) setStep(2, setPaymentStepState)
        break
      default:
      // noop
    }
  }, [step, accountId, isCardSubmitted, isAuthorized, claimIdFromUrl, setPaymentStepState])

  useEffect(() => {
    setFees(campaign, setPaymentStepState)
  }, [campaign, setPaymentStepState])

  return (
    <Box sx={{ maxWidth: 340, width: '100%' }}>
      <NFTHeader title={title} description={series?.metadata.description} />

      <Stepper activeStep={step} sx={{ mb: 4 }}>
        <Step>
          <StepLabel>
            <Typography variant="body1" component="span">
              {t('wallet')}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography variant="body1" component="span">
              {t('payment')}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography variant="body1" component="span">
              {t('receipt')}
            </Typography>
          </StepLabel>
        </Step>
      </Stepper>

      <Grid container>
        {step === 0 && <AccountStep campaign={campaign} />}
        {step === 1 && <ProcessorStep title={title} campaign={campaign} />}
        {step === 2 && <ReceiptStep />}
      </Grid>
    </Box>
  )
}

export default PaymentStepper
