enum PaymentProcessor {
  Spreedly = 'spreedly',
}

enum SpreedlyCardType {
  Alelo = 'alelo',
  Alia = 'alia',
  AmericanExpress = 'american_express',
  Cabal = 'cabal',
  Carnet = 'carnet',
  Confiable = 'confiable',
  Dankort = 'dankort',
  DinerClub = 'diners_club',
  Discover = 'discover',
  Elo = 'elo',
  JCB = 'jcb',
  Maestro = 'maestro',
  MasterCard = 'master',
  Naranja = 'naranja',
  Olimpica = 'olimpica',
  Sodexo = 'sodexo',
  Visa = 'visa',
  VR = 'vr',
}

export { SpreedlyCardType, PaymentProcessor }
