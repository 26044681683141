import { Grid, Link, Typography } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { responsiveTypography } from '../utils/styles'

interface NoTokensRemainProps {
  title: string
  showTitle?: boolean
}
const NoTokensRemain = ({ title, showTitle = false }: NoTokensRemainProps) => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{ maxWidth: 340, width: '100%' }}
    >
      {showTitle && (
        <Typography variant="subtitle1" component="h1" sx={responsiveTypography()}>
          {title}
        </Typography>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          mb: 4,
          justifyContent: {
            xs: 'center',
            sm: 'flex-start',
          },
        }}
      >
        <img src="/inactive-error.png" style={{ height: '73px' }} alt="success"></img>
      </Grid>
      <Typography variant="h1" component="h1" sx={responsiveTypography()}>
        {t('noRemainingSupply')}
      </Typography>
      <Typography variant="body1" component="p" sx={responsiveTypography()}>
        <Trans i18nKey="allTokensMinted">
          All tokens have been minted. Check
          <Link href={'https://fewfar.com/'} variant="body2" target="blank" rel="noreferer nofollow">
            secondary markets
          </Link>
          for availability.
        </Trans>
      </Typography>
    </Grid>
  )
}

export default NoTokensRemain
