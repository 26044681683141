import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import LetsGo from '../LetsGo'
import PaymentStepper from './PaymentStepper'
import { useRecoilState } from 'recoil'
import { claimIdFromUrlState } from '../../store'

interface PaymentProps {
  campaign: Campaign
  title: string
}

const Payment = ({ campaign, title }: PaymentProps) => {
  const [showIntro, setShowIntro] = useState(false) // always keep false. interrupts wallet/3ds redirects
  const [claimIdFromUrl] = useRecoilState(claimIdFromUrlState)

  useEffect(() => {
    if (claimIdFromUrl) {
      setShowIntro(false)
    }
    // eslint-disable-next-line
  }, [])

  function handleShowIntro() {
    setShowIntro(false)
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justifyContent="start"
      sx={{
        alignItems: {
          xs: 'center',
          sm: 'start',
        },
      }}
    >
      {showIntro ? (
        <LetsGo campaign={campaign} title={title} handleShowIntro={handleShowIntro} />
      ) : (
        <PaymentStepper campaign={campaign} title={title} />
      )}
    </Grid>
  )
}

export default Payment
