import { SetterOrUpdater } from 'recoil'
import { getFees } from '../../../utils/order'
import { PaymentStepState } from '../../models/payment'

const setStep = (value: number, set: SetterOrUpdater<PaymentStepState>) => set((state) => ({ ...state, step: value }))

const setIsCardSubmitted = (set: SetterOrUpdater<PaymentStepState>) =>
  set((state) => ({ ...state, isCardSubmitted: true }))

const setIsAuthorized = (set: SetterOrUpdater<PaymentStepState>) => set((state) => ({ ...state, isAuthorized: true }))

const setWalletId = (value: string, set: SetterOrUpdater<PaymentStepState>) =>
  set((state) => ({ ...state, walletId: value }))

const setFees = (campaign: Campaign, set: SetterOrUpdater<PaymentStepState>) =>
  set((state) => ({ ...state, ...getFees(campaign.requirements.fiatPayment!.price) }))

export { setIsCardSubmitted, setIsAuthorized, setFees, setWalletId, setStep }
