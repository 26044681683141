let DEFAULT_NETWORK_ID = 'mainnet'
if (
  window.location.origin.includes('dev') ||
  window.location.origin.includes('testnet') ||
  window.location.origin.includes('localhost') ||
  window.location.origin.includes('ngrok')
) {
  DEFAULT_NETWORK_ID = 'testnet'
}

export { DEFAULT_NETWORK_ID }
