type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestData {
  route: string
  method?: RequestMethod
  body?: any
  headers?: any
}

const REQUEST_BASE: RequestData = {
  route: '/',
  method: 'GET',
  headers: {},
}

export default class ApiService {
  baseURL: string

  constructor(baseURL: string) {
    this.baseURL = baseURL
  }

  async get(data: RequestData | string) {
    if (typeof data === 'string') {
      return this.sendRequest({
        ...REQUEST_BASE,
        route: data,
      })
    } else {
      return this.sendRequest({
        ...REQUEST_BASE,
        ...data,
      })
    }
  }

  async post(data: RequestData) {
    return this.sendRequest({
      ...REQUEST_BASE,
      ...data,
      method: 'POST',
    })
  }

  async put(data: RequestData) {
    return this.sendRequest({
      ...REQUEST_BASE,
      ...data,
      method: 'PUT',
    })
  }

  private async sendRequest(data: RequestData): Promise<any> {
    const { route, ...rest } = data
    if (!route) throw new Error('invalid route path')
    try {
      let res = await fetch(`${this.baseURL}${route}`, rest)
      if (!res.ok) return res
      if (res.headers.get('content-type')?.includes('application/json')) res = await res.json()
      return res
    } catch (e) {
      console.log('error:', e)
      return e
    }
  }
}
