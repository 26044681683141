import { FilledTextFieldProps, TextField as MuiTextField } from '@mui/material'
import { useCallback, useState } from 'react'

type TextFieldProps = Omit<FilledTextFieldProps, 'onChange' | 'variant'> & {
  variant?: FilledTextFieldProps['variant']
  validate?: (value: string) => boolean
  onChange?: (value: string) => void
  onChangeAfterTimeout?: (value: string) => void
  onValidityChange?: (isValid: boolean) => void
  onTypingChange?: (isTyping: boolean) => void
  timeout?: number
}

function TextField({
  error,
  value,
  validate,
  onChange,
  onValidityChange,
  onTypingChange,
  InputProps = {},
  variant = 'filled',
  size = 'small',
  fullWidth = true,
  timeout = 500,
  ...props
}: TextFieldProps) {
  const [{ isDirty, isValid, isTyping, timeoutRef }, setState] = useState<{
    isDirty: boolean
    isValid: boolean
    isTyping: boolean
    timeoutRef: NodeJS.Timeout | undefined
  }>({
    isDirty: false,
    isValid: false,
    isTyping: false,
    timeoutRef: undefined,
  })

  const handleTimeoutComplete = useCallback(() => {
    if (typeof onTypingChange === 'function') {
      onTypingChange(false)
    }
    setState((state) => ({ ...state, isTyping: false }))
  }, [onTypingChange])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const hasValidity = typeof validate !== 'undefined'
      let isValid: boolean = !hasValidity || true
      const value = event.target?.value

      if (hasValidity) {
        isValid = validate(value)
      }
      if (typeof onChange === 'function') {
        onChange(value)
      }
      if (hasValidity && typeof onValidityChange === 'function') {
        onValidityChange(isValid)
      }
      if (!isTyping && typeof onTypingChange === 'function') {
        onTypingChange(true)
      }

      if (typeof timeoutRef !== 'undefined') {
        clearTimeout(timeoutRef)
      }
      const ref = setTimeout(handleTimeoutComplete, timeout)

      setState((state) => ({
        ...state,
        isValid,
        isDirty: true,
        isTyping: true,
        timeoutRef: ref,
        value,
      }))
    },
    [onChange, onValidityChange, validate, handleTimeoutComplete, onTypingChange, isTyping, timeoutRef, timeout]
  )

  return (
    <MuiTextField
      value={value}
      variant={variant}
      onChange={handleChange}
      error={(isDirty && !isValid) || (typeof error !== 'undefined' ? error : false)}
      InputProps={{ disableUnderline: true, ...InputProps }}
      fullWidth={fullWidth}
      size={size}
      {...props}
    />
  )
}

export default TextField
