import { callViewMethod } from '../services/near'

/**
 * Checks NFT contract for remaining supply
 * @param campaign
 * @returns [any, boolean]
 */
export async function checkSupplyRemains(campaign: Campaign) {
  const [contractId, title] = campaign.seriesId.split('/')

  const [nftRes, supplyRes] = await Promise.all([
    callViewMethod(contractId, 'nft_get_type', { token_type_title: title }),
    callViewMethod(contractId, 'nft_supply_for_type', { token_type_title: title }),
  ])

  const max = parseInt(nftRes.metadata.copies, 10)
  const minted = parseInt(supplyRes, 10)
  const isSupplyRemaining = minted >= max ? false : true

  return [nftRes, isSupplyRemaining]
}
