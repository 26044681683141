import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Landing from './pages/Landing'
import Redirect from './pages/Redirect'
import { I18nextProvider } from 'react-i18next'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import i18n from './i18n'

const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SECRET!}
      useRecaptchaNet={true}
      useEnterprise={false}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <I18nextProvider i18n={i18n}>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/redirect" component={Redirect} /> {/* Come in from Twitter, TikTok */}
              <Route path="/" component={Landing} />
            </Switch>
          </Router>
        </div>
      </I18nextProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
