import { Box, Grid, GridProps } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlaceholder from 'react-placeholder'
import Mimetype from 'mime'
import { getIpfsMediaUrl } from '../utils/ipfs'
import useIntersection from '../hooks/useIntersection'

interface FileViewerProps {
  mediaID: string | undefined
  fromIPFS?: boolean
  isDragAndDrop?: boolean // for dynamic height containers
}

interface FileViewerInnerProps {
  url: string | undefined
  filetype: string
  isDragAndDrop: boolean
}

// preloads video metadata, buffers while in viewport
function VideoViewer({ url, filetype }: FileViewerInnerProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isInViewport, setIsInViewport] = useState<boolean>(false)
  const videoRef = useCallback(
    (node: HTMLVideoElement) => {
      if (node) {
        if (isInViewport) {
          node.play()
        } else {
          node.pause()
        }
      }
    },
    [isInViewport]
  )
  const intersection = useIntersection(ref, {})
  const onLoad = useCallback(() => setIsLoaded(true), [])

  useEffect(() => {
    if (ref.current && intersection) {
      const isInViewport = intersection?.intersectionRatio > 0
      setIsInViewport(isInViewport)
    }
  }, [ref, intersection])

  return (
    <>
      <div ref={ref} style={{ width: '100%', height: '100%', position: 'absolute' }}>
        {!isLoaded && (
          <video preload="metadata" style={{ display: 'none' }} onLoadedMetadata={onLoad} onLoad={onLoad}>
            <source src={url} type={filetype} />
          </video>
        )}
      </div>
      <ReactPlaceholder showLoadingAnimation={true} type="round" ready={isLoaded} delay={100}>
        <video ref={videoRef} loop muted width="100%">
          <source src={url} type={filetype} />
        </video>
      </ReactPlaceholder>
    </>
  )
}

// preloads audio metadata, only buffers when user hits play
function AudioViewer({ url, filetype }: FileViewerInnerProps) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const onLoad = useCallback(() => setIsLoaded(true), [])

  return (
    <>
      {!isLoaded && (
        <audio preload="metadata" style={{ display: 'none' }} onLoadedMetadata={onLoad} onLoad={onLoad}>
          <source src={url} type={filetype} />
        </audio>
      )}
      <ReactPlaceholder showLoadingAnimation={true} type="round" ready={isLoaded} delay={100}>
        <audio controls onLoad={onLoad}>
          <source src={url} type={filetype} />
        </audio>
      </ReactPlaceholder>
    </>
  )
}

// preloads nothing, loads image only after coming into viewport
function ImageViewer({ url }: FileViewerInnerProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [wasInViewport, setWasInViewport] = useState<boolean>(false)
  const intersection = useIntersection(ref, {})

  const onLoad = useCallback(() => {
    setIsLoaded(true)
  }, [setIsLoaded])

  useEffect(() => {
    if (ref.current && intersection && !wasInViewport) {
      const wasInViewport = intersection?.intersectionRatio > 0
      setWasInViewport(wasInViewport)
    }
  }, [ref, intersection, wasInViewport])

  return (
    <>
      <div ref={ref}>
        {!isLoaded && wasInViewport && <img style={{ display: 'none' }} src={url} alt="nft" onLoad={onLoad} />}
      </div>
      <ReactPlaceholder showLoadingAnimation={true} type="round" ready={isLoaded} delay={10}>
        <img style={{ width: '100%', borderRadius: '6px' }} src={url} alt="nft" />
      </ReactPlaceholder>
    </>
  )
}

function FileViewer({
  mediaID,
  fromIPFS = false,
  isDragAndDrop = false,
  sx = {},
  ...props
}: FileViewerProps & GridProps) {
  const url = fromIPFS ? getIpfsMediaUrl(mediaID as string) : mediaID
  const viewer = useMemo(() => {
    if (!url) {
      return null
    }

    let filetype: string | null
    const isDataUrl = url.startsWith('data:')
    if (isDataUrl) {
      filetype = url.substring(5, url.indexOf(';'))
    } else {
      filetype = Mimetype.getType(url)
    }

    if (!filetype) {
      filetype = 'image/jpg' // fallback to image as a last resort
    }

    const isVideo = filetype.includes('video')
    const isAudio = filetype.includes('audio')
    if (isVideo) {
      return <VideoViewer url={url} isDragAndDrop={isDragAndDrop} filetype={filetype} />
    }
    if (isAudio) {
      return <AudioViewer url={url} isDragAndDrop={isDragAndDrop} filetype={filetype} />
    }
    return <ImageViewer url={url} isDragAndDrop={isDragAndDrop} filetype={filetype} />
  }, [url, isDragAndDrop])

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ borderRadius: '6px', width: '200px', height: '200px', ...sx }}
      {...props}
    >
      <Box
        sx={{
          borderRadius: '6px',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: {
            xs: 'center',
            sm: 'flex-start',
          },
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {viewer}
      </Box>
    </Grid>
  )
}

export default FileViewer
