import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import LetsGo from './LetsGo'
import CheckInStepper from './CheckInStepper'
import { claimIdFromUrlState } from '../store'
import { useRecoilState } from 'recoil'

interface CheckInProps {
  campaign: Campaign
  title: string
}

const CheckIn = ({ campaign, title }: CheckInProps) => {
  const [showIntro, setShowIntro] = useState(true)
  const [claimIdFromUrl] = useRecoilState(claimIdFromUrlState)

  useEffect(() => {
    if (claimIdFromUrl) {
      setShowIntro(false)
    }
    // eslint-disable-next-line
  }, [])

  function handleShowIntro() {
    setShowIntro(false)
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justifyContent="start"
      sx={{
        alignItems: {
          xs: 'center',
          sm: 'start',
        },
      }}
    >
      {showIntro ? (
        <LetsGo campaign={campaign} title={title} handleShowIntro={handleShowIntro} />
      ) : (
        <CheckInStepper campaign={campaign} title={title} />
      )}
    </Grid>
  )
}

export default CheckIn
