import {useState, useCallback} from 'react'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {SonarService} from '../services/SonarService'
import constants from '../utils/constants'

export default function useReCaptchaVerification() {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [isCaptchaLoading, setIsCaptchaLoading] = useState(false)
    const [captchaSuccess, setCaptchaSuccess] = useState(true)

    /**
     * Calls the ReCaptcha token generator and then passes received token to the Sonar API recaptcha endpoint returning
     * an object that indicates whether a client is a robot with success boolean.
     * A recaptcha string can be passed to support a source map from which site actions are spawning token
     * generation. See: https://developers.google.com/recaptcha/docs/v3#actions
     */
    const handleReCaptchaVerify = useCallback(async (reCaptchaAction: string = '') => {
        if (!executeRecaptcha) return
        setIsCaptchaLoading(true)
        const token = await executeRecaptcha(reCaptchaAction)
        let res = await SonarService.validateReCaptcha(constants.SONAR_API_KEY, {token: token})
        return JSON.parse(res)
    }, [executeRecaptcha])

    /**
     * Provides an event wrapper to trigger user spawned events upon verification from sonar-api that they are not a
     * robot (using a headless browser, etc.) and sets state related to loading conditions and recaptcha success.
     */
    const handleFraudPreventEventWrapper = (callback: () => void, reCaptchaAction: string) => {
        handleReCaptchaVerify(reCaptchaAction).then((res) => {
            if (res.success) {
                setCaptchaSuccess(true)
                callback()
            } else {
                setCaptchaSuccess(false)
            }
            setIsCaptchaLoading(false)
        })
    }

    return {
        handleFraudPreventEventWrapper,
        isCaptchaLoading,
        captchaSuccess
    }
}