import { SvgIcon } from '@mui/material'

export function NearIcon({ color = '#FFF' }: { color?: string }) {
  return (
    <SvgIcon sx={{ mt: '3px' }}>
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5 6L17.5 1V17L1.5 1V17L6.5 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default NearIcon
