import './Loading.scss'

const Loading = () => (
  <div className="loading">
    <div className="lds-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

export default Loading
