import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { SonarService } from '../services/SonarService'
import constants from '../utils/constants'
import { Grid, Typography, Box, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Claim from './Claim'
import { getClaimIdFromClaimLink } from '../utils/claimlinks'
import { claimIdFromUrlState, seriesState } from '../store'
import { useRecoilState } from 'recoil'
import { ThumbUp as ThumbUpIcon, Check as CheckIcon } from '@mui/icons-material'
import { responsiveTypography } from '../utils/styles'
import NFTHeader from './NFTHeader'

interface CheckInStepperProps {
  campaign: Campaign
  title: string
}

export default function CheckInStepper({ campaign, title }: CheckInStepperProps) {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const [claimIdFromUrl] = useRecoilState(claimIdFromUrlState)
  const [series] = useRecoilState(seriesState)

  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [codeVerified, setCodeVerified] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [claimId, setClaimId] = useState<string | undefined>()

  useEffect(() => {
    if (codeVerified) {
      setActiveStep(1)
    }
    if (claimIdFromUrl) {
      setClaimId(claimIdFromUrl)
      setActiveStep(1)
    }
    // eslint-disable-next-line
  }, [codeVerified])

  const handleValidateCode = async () => {
    console.log('code', code)
    if (!code) {
      setError(t('enterCode'))
    } else {
      setIsSubmitting(true)
      try {
        let res = await SonarService.validateCode(code, constants.SONAR_API_KEY, campaign)
        console.log('res.status', res.status)
        if (res.status === 500) {
          setError(t('tryAgain'))
        } else if (res.status === 400) {
          setError(t('invalidCode'))
        } else {
          const { claimLink } = res as { claimLink: string }
          console.log('claimlink', claimLink)
          const id = getClaimIdFromClaimLink(claimLink)
          setClaimId(id)
          setCodeVerified(true)
        }
      } catch (e) {
        console.log('here?')
        setError(t('invalidCode'))
      }
      setIsSubmitting(false)
    }
  }

  return (
    <Box sx={{ maxWidth: 340, width: '100%' }}>
      <NFTHeader title={title} description={series?.metadata.description} />

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        <Step>
          <StepLabel>
            <Typography variant="body1" component="span">
              {t('verify')}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography variant="body1" component="span">
              {t('claim')}
            </Typography>
          </StepLabel>
        </Step>
      </Stepper>

      <Grid container>
        {/* 6-DIGIT VERIFICATION CODE */}
        {activeStep === 0 && (
          <Grid container sx={{ mb: 2 }}>
            <Typography variant="h1" component="h1" sx={responsiveTypography(3)}>
              {t('enterCode')}
            </Typography>

            <TextField
              autoFocus
              id="code-input"
              label={t('code')}
              variant="filled"
              type="number"
              onFocus={() => {
                if (error) setError('')
              }}
              error={error.length === 0 ? false : true}
              helperText={error.length === 0 ? null : error}
              onChange={(e) => {
                if (e.target.value.length <= 6) setCode(e.target.value)
              }}
              fullWidth
              InputProps={{ disableUnderline: true }}
              sx={{ mb: 3 }}
            />

            <Typography variant="body1" component="p" sx={responsiveTypography()}>
              {t('codeWillRotate')}
            </Typography>

            <LoadingButton
              loading={isSubmitting}
              loadingPosition="end"
              disabled={codeVerified}
              variant="contained"
              color="secondary"
              onClick={(e) => {
                e.preventDefault()
                handleValidateCode()
              }}
              endIcon={codeVerified ? <ThumbUpIcon /> : <CheckIcon />}
              sx={{ mb: 2 }}
            >
              {codeVerified ? t('verified') : t('verify')}
            </LoadingButton>
          </Grid>
        )}

        {/* CLAIM */}
        {activeStep === 1 && claimId && <Claim campaign={campaign} claimId={claimId} />}
      </Grid>
    </Box>
  )
}
