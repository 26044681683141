/* eslint-disable import/no-anonymous-default-export */

import { DEFAULT_NETWORK_ID } from '../services'

type Constants = Record<any, any>

export default {
  LANGUAGES: ['en', 'es'],
  SONAR_API_KEY: process.env.REACT_APP_SONAR_API_KEY || '',
  SPEARMINT_ADMIN_API_KEY: process.env.REACT_APP_SPEARMINT_ADMIN_API_KEY || '',
  SPEARMINT_ADMIN_APP_NAME: process.env.REACT_APP_SPEARMINT_ADMIN_APP_NAME || '',
  EXPLORER_DOMAIN: DEFAULT_NETWORK_ID === 'mainnet' ? 'https://explorer.near.org' : 'https://explorer.testnet.near.org',
  SATORI_INFO_EMAIL: 'info@satori.art',
  THEMES: {
    default: {
      backgroundColor: '#e5e5e5',
      textPrimary: '#000000',
      textSecondary: '#ffffff',
    },
  },
  FEES: {
    SATORI: {
      baseUSD: 0.75,
      percent: 2.5,
    },
    WYRE: {
      baseUSD: 0.3,
      percent: 4.1,
    },
  },
  LINKDROP_RES_ID_PREFIX: 'LINKDROP_RES_ID_',
} as Constants
