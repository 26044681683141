import {
  WyreExceptionResponse,
  wyreValidationExceptionMap,
  wyreValidationExceptionMapNA,
} from '../services/models/wyre'

enum WyreFormElement {
  None,
  Form,
  Phone,
  Email,
  Street,
  City,
  State,
  PostalCode,
  Country,
}

function getWyreException(exception: WyreExceptionResponse): [string | undefined, number] {
  const { errorCode, message = 'An unknown error occurred', type } = exception
  console.log('errorCode:   ', errorCode)
  console.log('message:     ', message)
  console.log('type:        ', type)

  const isWyreError = typeof errorCode !== 'undefined' && typeof type !== 'undefined'
  if (!isWyreError) {
    return [message, WyreFormElement.Form]
  }

  const [first, ...rest] = errorCode.split('.')
  const code = rest.join('.')

  // WYRE DOESN'T HANDLE THESE
  // Not much user can do
  if (first === 'n/a') {
    const errorMessage = wyreValidationExceptionMapNA[first]
    return [errorMessage, WyreFormElement.Form]
  }
  // WYRE HANDLES THESE
  // user should correct something if possible
  const errorMessage = wyreValidationExceptionMap[first][code]
  switch (first) {
    // MISC. ERRORS - DISPLAY VIA MODAL
    case 'debitCardWhiteLabel':
    case 'hostedCheckout':
    case 'limits':
    case 'reservation':
      return [errorMessage, WyreFormElement.Form]
    // VALIDATION ERRORS
    case 'validation':
      switch (code) {
        // FORM RELATED - DISPLAY INLINE
        case 'missingGivenName':
          return [errorMessage, WyreFormElement.Form]
        case 'missingFamilyName':
          return [errorMessage, WyreFormElement.Form]
        case 'invalidPhoneNumber':
        case 'phoneNumRequiresCountryCode':
          return [errorMessage, WyreFormElement.Phone]
        case 'invalidEmail':
          return [errorMessage, WyreFormElement.Email]
        case 'missingAddressStreet1':
          return [errorMessage, WyreFormElement.Street]
        case 'missingAddressCity':
          return [errorMessage, WyreFormElement.City]
        case 'missingAddressState':
        case 'invalidUsState':
        case 'stateNotSupported':
          return [errorMessage, WyreFormElement.State]
        case 'avs.zipcode':
          return [errorMessage, WyreFormElement.PostalCode]
        case 'countryMissing':
        case 'unsupportedCountry':
        case 'lockCountry':
          return [errorMessage, WyreFormElement.Country]
        // CARD-RELATED - DISPLAY VIA MODAL (CONSTRAINED BY SPREEDLY IFRAME)
        case 'unsupportedCardType.credit':
        case 'unsupportedCardType.prepaid':
        case 'avs':
        case 'cardExpirationMonthOutOfRange':
        case 'cardExpirationMonth':
        case 'invalidExpirationDate':
        case 'cardExpirationYear':
        case 'invalidDebitCardNumber':
          return [errorMessage, WyreFormElement.State]
        // ADDRESS-RELATED (GENERIC) - DISPLAY VIA MODAL
        case 'avs.unavailable':
        case 'avs.incorrectBillingAddress':
        case 'avs.invalidBillingAddress':
          return [errorMessage, WyreFormElement.State]
        // OTHER - DISPLAY VIA MODAL
        case 'invalidReferrerId':
        case 'invalidFailureRedirectUrl':
        case 'invalidReservation':
        case 'invalidPaymentMethod':
        case 'invalidRedirectUrl':
        case 'referrerAccountId':
        case 'snapx.min':
          return [errorMessage, WyreFormElement.State]
      }
  }

  return [undefined, WyreFormElement.None]
}

export { getWyreException, WyreFormElement }
