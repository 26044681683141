const colors = {
  PRIMARY: '#4667E3',
  PRIMARY_HOVER: '#FFF',
  PRIMARY_HOVER_BG: 'rgba(70, 103, 227, 0.88)',
  PRIMARY_ACTIVE: 'rgba(255, 255, 255, 0.56)',
  PRIMARY_ACTIVE_BG: 'rgba(70, 103, 227, 0.72)',
  PRIMARY_DISABLED: 'rgba(70, 103, 227, 0.24)',
  PRIMARY_DISABLED_BG: 'rgba(70, 103, 227, 0.08)',

  SECONDARY: '#262626',
  SECONDARY_HOVER: '#FFF',
  SECONDARY_HOVER_BG: 'rgba(38, 38, 38, 0.88)',
  SECONDARY_ACTIVE: 'rgba(255, 255, 255, 0.56)',
  SECONDARY_ACTIVE_BG: 'rgba(38, 38, 38, 0.72)',
  SECONDARY_DISABLED: 'rgba(38, 38, 38, 0.16)',
  SECONDARY_DISABLED_BG: 'rgba(38, 38, 38, 0.08)',

  ERROR: '#DB5555',
  SUCCESS: '#3CBF68',

  BLUE: '#4667E3',
  BLUE_DISABLED: 'rgba(70, 103, 227, 0.56)',
}

export default colors
