import Constants from './constants'

const domain = Constants.EXPLORER_DOMAIN

const explorer = {
  getDomain: () => domain,
  getAccountURL: (id: string) => `${domain}/accounts/${id}`,
  getTxURL: (id: string) => `${domain}/transactions/${id}`,
}

export default explorer
