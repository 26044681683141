import { useTranslation } from 'react-i18next'
import { handleOpenEmailClient } from '../../utils/email'
import colors from '../../theme/colors'
import Constants from '../../utils/constants'
import { Typography, Box } from '@mui/material'

const ReCaptchaMessage = () => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
      <Typography variant="body1" component="span">
        {t('errors.youAreARobot')}{' '}
      </Typography>
      <Typography
        variant="body1"
        component="span"
        onClick={() => handleOpenEmailClient(Constants.SATORI_INFO_EMAIL)}
        sx={{ color: `${colors.BLUE_DISABLED} !important`, cursor: 'pointer' }}
      >
        {Constants.SATORI_INFO_EMAIL}
      </Typography>
    </Box>
  )
}

export default ReCaptchaMessage
