import ApiService from './ApiService'
import { DEFAULT_NETWORK_ID } from '.'

class SonarApiService {
  private ApiService: ApiService

  routes = {
    CODE: {
      GENERATE: (campaignId: string, email: string, msValid: number) =>
        encodeURI(`code/${campaignId}?email=${email}&msValid=${msValid}`),
      VALIDATE: (campaignId: string, code: string, email: string | undefined) => {
        const url = `code/${campaignId}?code=${code}${email ? `&email=${email}` : ''}`
        const encoded = encodeURI(url)
        return encoded
      },
    },
    OAUTH: {
      TWITTER: {
        REDIRECT: (fingerprint: string) => {
          const redirectURL = `${window.location.origin}/redirect/twitter`
          console.log('redirectUrl: ', redirectURL)
          const encoded = encodeURIComponent(redirectURL)
          return `oauth/twitter-redirect?id=${fingerprint}&redirectUrl=${encoded}`
        },
        CALLBACK: (campaignId: string, fingerprint: string, claimlink: boolean | undefined) =>
          `oauth/twitter-callback?campaignId=${campaignId}&id=${fingerprint}${claimlink ? '&claimlink=true' : ''}`,
        QUERY_RETWEET: (campaignId: string, tweetId: string, username: string, claimlink: boolean) =>
          `oauth/twitter-retweets?campaignId=${campaignId}&tweet_id=${tweetId}&username=${username}&claimlink=${claimlink}`,
      },
      TIKTOK: {
        REDIRECT: (campaignId: string) => `oauth/tiktok-redirect?campaignId=${campaignId}`,
        CALLBACK: (code: string, state: string, campaignId: string) =>
          `oauth/tiktok-callback?campaignId=${campaignId}&code=${code}&state=${state}`,
        VERIFY: (campaignId: string, displayName: string) =>
          `oauth/tiktok-verify?campaignId=${campaignId}&displayName=${displayName}`,
      },
    },
    CAMPAIGN: 'campaign',
    INCREMENT_VIEWS: (campaignId: string) => `campaign/${campaignId}/views-increment`,
    CREATE_QUOTATION: (campaignId: string) => `order/quote?campaignId=${campaignId}`,
    CREATE_ORDER: (campaignId: string) => `order/create?campaignId=${campaignId}`,
    AUTHORIZE_ORDER: (campaignId: string) => `order/authorize?campaignId=${campaignId}`,
    CLAIMLINK: (campaignId: string) => `claimlink?campaignId=${campaignId}`,
    CREATE_LINKDROP: (claimId: string, redirectUrl: string) =>
      `linkdrop?code=${claimId}&redirectUrl=${encodeURIComponent(redirectUrl)}`,
    RESERVE_CLAIM_ID_AND_CREATE_LINKDROP: (campaignId: string, redirectUrl: string) =>
      `order/reserve-claim-id?campaignId=${campaignId}&redirectUrl=${redirectUrl}`,
    CLAIM_NFT: (claimId: string, contractId: string, title: string, receiverId: string, campaignId: string) =>
      `claim-nft?code=${claimId}&contractId=${contractId}&title=${encodeURIComponent(
        title
      )}&receiverId=${receiverId}&campaignId=${campaignId}`,
    RECAPTCHA: 'verify/recaptcha',
  }

  constructor(baseUrl: string) {
    this.ApiService = new ApiService(baseUrl)
  }

  generateCode = async (campaignId: string, email: string, msValid: number, apiKey: string) => {
    return this.ApiService.get({
      route: this.routes.CODE.GENERATE(campaignId, email, msValid),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  validateCode = async (code: string, apiKey: string, campaign: Campaign, email?: string) => {
    const route = this.routes.CODE.VALIDATE(campaign.id, code, email)
    return this.ApiService.post({
      route,
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  validateReCaptcha = async (apiKey: string, body: any) => {
    return this.ApiService.post({
      route: `${this.routes.RECAPTCHA}`,
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }

  twitterRedirect = async (campaignId: string, fingerprint: string, apiKey: string) => {
    // console.log('apiKey: ', apiKey)
    return this.ApiService.get({
      route: this.routes.OAUTH.TWITTER.REDIRECT(fingerprint),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  twitterCallback = async (
    campaignId: string,
    fingerprint: string,
    oauthToken: string,
    oauthVerifier: string,
    apiKey: string,
    claimlink?: boolean
  ) => {
    return this.ApiService.get({
      route: this.routes.OAUTH.TWITTER.CALLBACK(campaignId, fingerprint, claimlink),
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'oauth-token': oauthToken,
        'oauth-verifier': oauthVerifier,
      },
    })
  }

  twitterQueryRetweet = async (
    campaignId: string,
    tweetId: string,
    username: string,
    claimlink: boolean,
    apiKey: string
  ) => {
    return this.ApiService.get({
      route: this.routes.OAUTH.TWITTER.QUERY_RETWEET(campaignId, tweetId, username, claimlink),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  tiktokRedirect = async (campaignId: string, apiKey: string) => {
    return this.ApiService.get({
      route: this.routes.OAUTH.TIKTOK.REDIRECT(campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  tiktokCallback = async (code: string, state: string, campaignId: string, apiKey: string) => {
    return this.ApiService.get({
      route: this.routes.OAUTH.TIKTOK.CALLBACK(code, state, campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  tiktokVerifyDisplayName = async (campaignId: string, displayName: string, apiKey: string) => {
    return this.ApiService.post({
      route: this.routes.OAUTH.TIKTOK.VERIFY(campaignId, displayName),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  getCampaign = async (campaignId: string, apiKey: string) => {
    return this.ApiService.get({
      route: `${this.routes.CAMPAIGN}/${campaignId}`,
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  getClaimLink = async (campaignId: string, apiKey: string) => {
    return this.ApiService.get({
      route: this.routes.CLAIMLINK(campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  createLinkdrop = async (claimId: string, redirectUrl: string, apiKey: string) => {
    return this.ApiService.get({
      route: this.routes.CREATE_LINKDROP(claimId, redirectUrl),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  reserveClaimIdAndCreateLinkdrop = async (campaignId: string, redirectUrl: string, apiKey: string) => {
    return this.ApiService.post({
      route: this.routes.RESERVE_CLAIM_ID_AND_CREATE_LINKDROP(campaignId, redirectUrl),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      body: {},
    })
  }

  claimNFT = async (
    claimId: string,
    contractId: string,
    title: string,
    receiverId: string,
    apiKey: string,
    campaignId: string
  ) => {
    return this.ApiService.get({
      route: this.routes.CLAIM_NFT(claimId, contractId, title, receiverId, campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  incrementCampaignViews = async (campaignId: string, apiKey: string) => {
    return this.ApiService.put({
      route: this.routes.INCREMENT_VIEWS(campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
  }

  createQuotation = async (campaignId: string, body: any, apiKey: string) => {
    return this.ApiService.post({
      route: this.routes.CREATE_QUOTATION(campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }

  createOrder = async (campaignId: string, body: any, apiKey: string) => {
    return this.ApiService.post({
      route: this.routes.CREATE_ORDER(campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }

  authorizeOrder = async (campaignId: string, body: any, apiKey: string) => {
    return this.ApiService.put({
      route: this.routes.AUTHORIZE_ORDER(campaignId),
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }
}

const SonarService = new SonarApiService(
  `${process.env.REACT_APP_API_HOST?.includes('http://') ? '' : 'https://'}${
    process.env.REACT_APP_API_HOST || `sonar-api-${DEFAULT_NETWORK_ID}.satori.art`
  }/api/`
)

export { SonarService }
