import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { SPREEDLY_API_KEY } from '../../../services/SpreedlyService'
import { SpreedlyCardType } from '../../../enum'
import { FilledInput, FormControl, FormHelperText, InputLabel } from '@mui/material'
import { useRecoilState } from 'recoil'
import { paymentFormState } from '../../../store'
import { setFormError, setPayment } from '../../../store/actions/payment/paymentForm'
import { MonthOption, YearOption } from '../models/common'
import Autocomplete from '../../Autocomplete'
import months from '../data/months.json'

declare global {
  interface Window {
    Spreedly: any
  }
}

function Spreedly() {
  const { t } = useTranslation()
  const [{ givenName, isFamilyNameValid, familyName, isGivenNameValid }, setPaymentState] =
    useRecoilState(paymentFormState)
  const years: YearOption[] = useMemo(() => {
    const currentYear = new Date().getFullYear()
    return new Array(21).fill(0).map((_, index) => ({
      label: String(currentYear + index),
      key: String(currentYear + index),
    }))
  }, [])

  const [
    {
      spreedly,
      didTokenize,
      cardNumberError,
      isCardNumberDirty,
      isCardNumberValid,
      isCardNumberFocused,
      cardCvvError,
      isCardCvvDirty,
      isCardCvvValid,
      isCardCvvFocused,
      expiryMonth,
      expiryMonthError,
      expiryYear,
      expiryYearError,
      isFormValid,
    },
    setState,
  ] = useState<{
    spreedly: any
    didTokenize: boolean
    cardNumberError: string | undefined
    isCardNumberDirty: boolean
    isCardNumberValid: boolean
    isCardNumberFocused: boolean
    cardCvvError: string | undefined
    isCardCvvDirty: boolean
    isCardCvvValid: boolean
    isCardCvvFocused: boolean
    expiryMonth: MonthOption
    expiryMonthError: string | undefined
    expiryYear: YearOption
    expiryYearError: string | undefined
    isFormValid: boolean
  }>({
    spreedly: undefined,
    didTokenize: false,
    cardNumberError: undefined,
    isCardNumberDirty: false,
    isCardNumberValid: false,
    isCardNumberFocused: false,
    cardCvvError: undefined,
    isCardCvvDirty: false,
    isCardCvvValid: false,
    isCardCvvFocused: false,
    expiryMonth: { label: 'January', key: '01' },
    expiryMonthError: undefined,
    expiryYear: years[1],
    expiryYearError: undefined,
    isFormValid: false,
  })

  const handleExpiryMonthChange = useCallback(
    (_: any, value: MonthOption) => {
      setFormError(undefined, setPaymentState)
      setState((state) => ({
        ...state,
        expiryMonth: value,
        expiryMonthError: undefined,
        expiryYearError: undefined,
        didTokenize: false,
      }))
    },
    [setPaymentState]
  )

  const handleExpiryYearChange = useCallback(
    (_: any, value: YearOption) => {
      setFormError(undefined, setPaymentState)
      setState((state) => ({
        ...state,
        expiryYear: value,
        expiryMonthError: undefined,
        expiryYearError: undefined,
        didTokenize: false,
      }))
    },
    [setPaymentState]
  )

  const handlePaymentToken = useCallback(
    (token: string, formData: any) => {
      const { month, year } = formData
      setPayment({ expiryMonth: month, expiryYear: year, paymentToken: token }, setPaymentState)
    },
    [setPaymentState]
  )

  const handleSpreedlyReady = useCallback(() => {
    if (spreedly) {
      spreedly.setFieldType('number', 'text')
      spreedly.setNumberFormat('prettyFormat')

      const style = [
        'font-size: 16px',
        'padding: 4px 0 5px',
        'height: 23px',
        'width: 100%',
        'padding: 21px 12px 4px 12px',
      ].join(';')
      spreedly.setStyle('number', style)
      spreedly.setStyle('cvv', style)
    }
    setState((state) => ({ ...state, isSpreedlyLoaded: true }))
  }, [spreedly])

  const handleSpreedlyError = useCallback((errors: SpreedlyTokenError[]) => {
    const nextState: { [key: string]: any } = {}
    errors.forEach(({ key, attribute }) => {
      switch (key) {
        case 'errors.invalid':
          if (attribute === 'number') {
            nextState.cardNumberError = t('errors.invalidCardNumber')
            nextState.isCardNumberValid = false
            nextState.isCardNumberDirty = true
          } else if (attribute === 'cvv') {
            nextState.cardCvvError = t('errors.invalidCardCvv')
            nextState.isCardCvvValid = false
            nextState.isCardCvvDirty = true
          } else if (attribute === 'year') {
            nextState.expiryYearError = t('errors.invalidCardYear')
          } else if (attribute === 'month') {
            nextState.expiryMonthError = t('errors.invalidCardMonth')
          }
          break
        case 'errors.expired':
          if (attribute === 'year') {
            nextState.expiryYearError = t('errors.expiredCardYear')
          } else if (attribute === 'month') {
            nextState.expiryMonthError = t('errors.expiredCardMonth')
          }
          break
        default:
          // noop
          break
      }
    })
    setState((state) => ({ ...state, ...nextState }))
    setFormError(undefined, setPaymentState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSpreedlyField = useCallback((name: string, type: string, activeElement: string, inputData: any) => {
    const nextState: { [key: string]: any } = {}

    if (type === 'input') {
      nextState.didTokenize = false

      const { validNumber, validCvv, cardType, numberLength, cvvLength } = inputData
      const isValidCardType =
        !cardType || cardType === SpreedlyCardType.Visa || cardType === SpreedlyCardType.MasterCard

      switch (name) {
        case 'number': {
          nextState.isCardNumberDirty = isCardNumberDirty || numberLength > 0

          if (isValidCardType) {
            nextState.isCardNumberValid = validNumber
            nextState.cardNumberError = undefined
          } else {
            nextState.isCardNumberValid = false
            nextState.cardNumberError = t('errors.unsupportedCardType')
          }
          break
        }
        case 'cvv':
          nextState.isCardCvvValid = validCvv
          nextState.isCardCvvDirty = isCardCvvDirty || cvvLength > 0
          break
        default:
          // noop
          break
      }
    } else if (type === 'focus' || type === 'blur') {
      const isFocused = type === 'focus'
      switch (name) {
        case 'number':
          nextState.isCardNumberFocused = isFocused
          nextState.isCardCvvFocused = false
          break
        case 'cvv':
          nextState.isCardCvvFocused = isFocused
          nextState.isCardNumberFocused = false
          break
        default:
          nextState.isCardCvvFocused = false
          nextState.isCardNumberFocused = false
          break
      }
    }

    setState((state) => ({ ...state, ...nextState }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!window.Spreedly) {
      const script = document.createElement('script')
      script.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js'
      script.async = true
      document.body.appendChild(script)
    }

    let shouldAttemptInit = true
    function initSpreedly() {
      if (window.Spreedly) {
        setState((state) => ({ ...state, spreedly: window.Spreedly }))
      } else {
        if (shouldAttemptInit) {
          setTimeout(initSpreedly, 10)
        }
      }
    }
    initSpreedly()

    return () => {
      shouldAttemptInit = false
    }
  }, [])

  useEffect(() => {
    if (spreedly) {
      spreedly.on('ready', handleSpreedlyReady)
      spreedly.on('errors', handleSpreedlyError)
      spreedly.on('paymentMethod', handlePaymentToken)
      spreedly.on('consoleError', (error: Error) => console.error(error))
      spreedly.on('fieldEvent', handleSpreedlyField)
      spreedly.init(SPREEDLY_API_KEY, {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv',
      })
    }

    return () => {
      if (spreedly) {
        spreedly.removeHandlers()
      }
    }
    // eslint-disable-next-line
  }, [spreedly])

  useEffect(() => {
    const isFormValid = isGivenNameValid && isFamilyNameValid && isCardNumberValid && isCardCvvValid
    setState((state) => ({ ...state, isFormValid }))
  }, [isGivenNameValid, isFamilyNameValid, isCardNumberValid, isCardCvvValid])

  useEffect(() => {
    const shouldSubmitForTokenization = spreedly && isFormValid && !didTokenize
    if (shouldSubmitForTokenization) {
      console.log('tokenize', {
        full_name: `${givenName} ${familyName}`,
        month: expiryMonth.key,
        year: expiryYear.key,
      })
      spreedly.tokenizeCreditCard({
        full_name: `${givenName} ${familyName}`,
        month: expiryMonth.key,
        year: expiryYear.key,
      })
      setState((state) => ({ ...state, didTokenize: true }))
    }
  }, [isFormValid, didTokenize, spreedly, givenName, familyName, expiryMonth, expiryYear])

  return (
    <Grid item container sx={{ pt: '0 !important' }} direction="row" justifyContent="space-between" spacing={3}>
      <Grid item xs={8}>
        <FormControl>
          <InputLabel
            htmlFor="spreedly-number"
            variant="filled"
            shrink={isCardNumberDirty || isCardNumberFocused}
            error={isCardNumberDirty && !isCardNumberValid && !isCardNumberFocused}
          >
            {t('cardNumber')}
          </InputLabel>
          <FilledInput
            components={{ Input: 'div' }}
            error={isCardNumberDirty && !isCardNumberValid}
            disableUnderline
            componentsProps={{
              input: { id: 'spreedly-number', 'aria-describedby': 'spreedly-number-text', style: { maxHeight: 50 } },
            }}
          />
          <FormHelperText id="spreedly-number-text" error>
            {cardNumberError}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl>
          <InputLabel
            htmlFor="spreedly-cvv"
            variant="filled"
            shrink={isCardCvvDirty || isCardCvvFocused}
            error={isCardCvvDirty && !isCardCvvValid && !isCardCvvFocused}
          >
            {t('cardCvv')}
          </InputLabel>
          <FilledInput
            components={{ Input: 'div' }}
            error={isCardCvvDirty && !isCardCvvValid}
            disableUnderline
            componentsProps={{
              input: { id: 'spreedly-cvv', 'aria-describedby': 'spreedly-cvv-text', style: { maxHeight: 50 } },
            }}
          />
          <FormHelperText id="spreedly-cvv-text" error>
            {cardCvvError}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <Autocomplete
          id="month-select"
          label={t('month')}
          value={expiryMonth}
          onChange={handleExpiryMonthChange}
          options={months}
          error={expiryMonthError}
        />
      </Grid>
      <Grid item xs={5}>
        <Autocomplete
          id="year-select"
          label={t('year')}
          value={expiryYear}
          onChange={handleExpiryYearChange}
          options={years}
          error={expiryYearError}
        />
      </Grid>
    </Grid>
  )
}

export default Spreedly
