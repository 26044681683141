import confetti from 'canvas-confetti'

export function launchConfetti() {
  confetti({
    spread: 90,
    startVelocity: 50,
    gravity: 2,
    colors: ['#2FD2E9', '#3A90F4', '#B950E3'],
  })
}

export function getClaimIdFromClaimLink(claimLink: string) {
  const arr = claimLink.split('/')
  return arr[arr.length - 1]
}

export function getClaimIdFromURL(URL: string) {}
