export const responsiveTypography = (marginBottom: number = 4) => {
  return {
    mb: {
      xs: 2,
      sm: marginBottom,
    },
    flex: 1,
    width: '100%',
    textAlign: {
      xs: 'center',
      sm: 'left',
    },
  }
}
