export interface WyreExceptionResponse {
  language: string // 'en' - Indicates the language of the exception message
  compositeType: string // '' -
  subType: string // '' -
  exceptionId: string // 'test_R9NJ92' - A unique identifier for this exception. This is very helpful when contacting support
  errorCode: string // 'debitCardWhiteLabel.illegalReferrer'
  transient: boolean // false - In rare cases, an exception may signal true here to indicate a transient problem. This means the request can be safely re-attempted
  message: string // 'this credentials do not belong to the referrerAccountId' - A human-friendly description of the problem
  type: string // 'ValidationException' - The category of the exception. See https://docs.sendwyre.com/docs/error-codes#section-error-types
}

type WyreHTTPStatusCode = 400 | 401 | 403 | 404 | 429 | 500

type WyreErrorTypesMap = {
  [key in WyreHTTPStatusCode]: {
    [key: string]: string
  }
}

export const wyreErrorTypesMap: WyreErrorTypesMap = {
  400: {
    ValidationException: 'The action failed due to problems with the request.',
    TransferException: 'There was a problem completing your transfer request',
    InsufficientFundsException: 'You requested the use of more funds in the specified currency than were available',
    CustomerSupportException: 'Please contact us at support@sendwyre.com to resolve this!',
    AccountLockedException:
      'The account has had a locked placed on it for potential fraud reasons. The customer should contact Wyre support for follow-up.',
    MFARequiredException:
      'An MFA action is required to complete the request. In general you should not get this exception while using API keys',
  },
  401: {
    AccessDeniedException: 'You lack sufficient privilege to perform the requested action',
  },
  403: {
    LockoutException: 'The account or IP has been blocked due to detected malicious behavior',
  },
  404: {
    NotFoundException: 'You referenced something that could not be located',
  },
  429: {
    RateLimitException:
      'Your requests have exceeded your usage restrictions. Please contact us if you need this increased',
  },
  500: {
    UnknownException: 'A problem with our services internally. This should rarely happen',
  },
}

export interface WyreValidationExceptionMap {
  [key: string]: { [key: string]: string }
}

export const wyreValidationExceptionMapNA = {
  'n/a': 'There is an unhandled error by the Wyre Escrow API. Contract support@wyre.com',
}
export const wyreValidationExceptionMap: WyreValidationExceptionMap = {
  debitCardWhiteLabel: {
    featureNotEnabled: 'Feature to debit card not enabled for the current authentication',
    illegalReferrer: 'Illegal referrer',
  },
  hostedCheckout: {
    featureNotEnabled: 'Feature not enabled for this account id',
    illegalReferrer: 'Invalid referrer account id for hosted checkout referrer',
  },
  limits: {
    dailyLimitReached: 'You reached your daily limit.',
    weeklyLimitReached: 'You reached your weekly limit.',
    yearlyLimitReached: 'You reached your yearly limit',
  },
  reservation: {
    invalidLockedField: 'Locked field is invalid',
    lockEmptyField: 'A reservation locked field is empty (locked fields cannot be empty and must match)',
    requiredFieldEmpty: 'A reservation required field is empty (required values cannot be empty)',
    requiredFieldValueMismatch: 'A reservation locked field value did not match the received value',
  },
  validation: {
    invalidPhoneNumber:
      'You did not specify a valid E164 Phone number. Phone numbers must follow the International E.164 format.',
    amountSourceCurrency: 'If amount or source currency are locked, you must lock both (amount and sourceCurrency)',
    authorizationAlreadyValidated: 'Authorization code already validated for the current order',
    authorizationCodeExpired: 'Authorization expired',
    authorizationCodeMismatch: 'Authorization code mismatch',
    authorizationCodeNotFound: 'Authorization codes not found',
    authorizationInvalidReservation: 'Invalid reservation id while authorizing the order',
    authorizationInvalidState: 'Invalid authorization state',
    authorizationMaxAttempts: 'max attempts to authorize an order',
    avs: 'We are not able to use this card. General card validation failure, further details on why the card doesn’t work are unavailable.',
    'avs.incorrectBillingAddress': 'Invalid billing address. Your address is incorrect',
    'avs.invalidBillingAddress': 'Invalid billing address',
    'avs.unavailable':
      'Invalid billing address. We were not able to validate your address. Try again later. If Wyre address validation service is unavailable the user will have to try the transaction later',
    'avs.zipcode': 'Invalid billing address. Zip code is incorrect',
    cardExpirationMonth: 'card expiration month invalid',
    cardExpirationMonthOutOfRange: 'card expiration month must be from 01-12 range.',
    cardExpirationYear: 'card expiration year invalid',
    countryMissing: 'missing locked field country',
    invalidCode: 'invalid authorization code',
    invalidCodeLength: 'invalid authorization code length',
    invalidDebitCardNumber: 'invalid debit card number',
    invalidEmail: 'invalid email',
    invalidExpirationDate: 'invalid expiration date, usually in the past',
    invalidFailureRedirectUrl: 'failureRedirectUrl is not valid (must be a full http url with protocol)',
    invalidOrderStatus: 'invalid order status. Order cannot be authorized at the moment.',
    invalidPaymentMethod: 'invalid payment method provided (apple-pay or debit-card are the accepted values)',
    invalidRedirectUrl: 'redirectUrl is not valid (must be a full http url with protocol)',
    invalidReferrerId: 'Invalid referrerAccountId',
    invalidReservation: 'invalid reservation',
    invalidUsState: 'Invalid US state submitted',
    lockCountry: 'invalid locked country (if any of phone or country are locked, you must lock both)',
    missingAddressCity: 'missing city on address field',
    missingAddressState: 'missing state on address field',
    missingAddressStreet1: 'missing street1 on address field',
    missingFamilyName: 'familyName is not present',
    missingGivenName: 'givenName is not present',
    orderAuthorizationDetailsNotAvailable: 'auth details not available. This usually happens',
    orderNotFound: 'order not found',
    phoneNumRequiresCountryCode: 'Please include your country code preceded by a "+" with your phone number',
    referrerAccountId: 'invalid referrer account id for hosted checkout referrer',
    sourceCurrencyNotSupported: 'the specified sourceCurrency is not supported',
    stateNotSupported: 'If you specify a non supported US state. More info at https://www.sendwyre.com/licenses.',
    'snapx.min': 'Does not meet minimum transaction size',
    'unsupportedCardType.credit': 'Credit cards are not supported',
    'unsupportedCardType.prepaid': 'PrePaid cards are not supported',
    unsupportedCountry: 'Country not supported: “XX”',
    walletOrderAuthorizationCodeMissing: 'authorization request without a auth code on it',
  },
}

interface WyreWalletOrderError {
  errorCategory: string
  message: string
  notes?: string
}

export interface WyreWalletOrderErrorMap {
  [key: string]: WyreWalletOrderError
}

export const wyreWalletOrderErrors: WyreWalletOrderErrorMap = {
  BILLING_ADDRESS_MISMATCH: {
    errorCategory: 'PAYMENT',
    message: 'Billing address error, please check your info and try again.',
  },
  EXCEEDED_DAILY_LIMIT: {
    errorCategory: 'RATE_LIMIT',
    message: 'Transaction limit exceeded, please try again later.',
  },
  EXCEEDED_WEEKLY_LIMIT: {
    errorCategory: 'RATE_LIMIT',
    message: 'Transaction limit exceeded, please try again later.',
  },
  EXCEEDED_YEARLY_LIMIT: {
    errorCategory: 'RATE_LIMIT',
    message: 'Transaction limit exceeded, please try again later.',
  },
  ISO_8583_XX: {
    errorCategory: 'PAYMENT',
    message: 'See ISO 8583 error message list -&gt;',
    notes: 'XX replaced by ISO 8583 code. See full list here: …standard-payments/v1/fops/card/response-codes</a>',
  },
  ORDER_AMOUNT_TOO_HIGH: {
    errorCategory: 'RATE_LIMIT',
    message: 'Transaction amount too high, please try a smaller amount.',
  },
  PHONE_NUMBER_MUST_BE_MOBILE: {
    errorCategory: 'GENERAL',
    message: 'Only mobile cellphone numbers are supported.',
  },
  SMS_CONFIRMATION_FAILED: {
    errorCategory: 'GENERAL',
    message: 'SMS confirmation failed',
    notes: 'For debit card tx only',
  },
  THREE_D_SECURE_AUTHENTICATION_FAILED: { errorCategory: 'PAYMENT', message: '3DS authentication failed.', notes: '' },
  TRANSACTION_TIMEOUT: { errorCategory: 'GENERAL', message: 'Transaction timed out, please try again.' },
  UNABLE_TO_PROCESS: {
    errorCategory: 'GENERAL',
    message: 'Sorry, but we are unable to process your order at this time.',
  },
  UNKNOWN_ERROR: {
    errorCategory: 'PAYMENT',
    message: 'Issue with payment, please check your info and try again',
  },
  UNSUPPORTED_COUNTRY: { errorCategory: 'GENERAL', message: 'We do not support this country.' },
  UNSUPPORTED_STATE: {
    errorCategory: 'GENERAL',
    message: 'We do not support this state at this time but hope to soon.',
  },
}

interface WyreUnifiedErrorCode {
  description: string
  whoThrows: string
}

interface WyreUnifiedErrorCodeMap {
  [key: string]: WyreUnifiedErrorCode
}

export const wyreUnifiedErrorCodeMap: WyreUnifiedErrorCodeMap = {
  CARD_ISSUER_ERROR: { description: 'Specific card issuer (bank) error', whoThrows: 'Bank' },
  CARD_RESTRICTIONS: { description: 'Card issuer or law restrictions', whoThrows: 'Bank' },
  CUSTOMER_NOT_ELIGIBLE: { description: 'Customer not eligible for the order', whoThrows: 'Payment processor' },
  DOB_ERROR: { description: "Error with user's date of birth", whoThrows: 'Wyre' },
  DO_NOT_HONOR: { description: 'Do not honor', whoThrows: 'Bank' },
  EXCEEDED_DAILY_LIMIT: { description: 'Exceeded daily purchase limit', whoThrows: 'Wyre' },
  EXCEEDED_WEEKLY_LIMIT: { description: 'Exceeded weekly purchase limit', whoThrows: 'Wyre' },
  EXCEEDED_YEARLY_LIMIT: { description: 'Exceeded yearly purchase limit', whoThrows: 'Wyre' },
  EXPIRED_CARD: { description: 'Card expiration date is invalid', whoThrows: 'Bank' },
  GENERAL_BANK_ERROR: { description: 'General bank error', whoThrows: 'Bank' },
  HIGH_RISK_SCORE: { description: 'High risk, fraud suspicious', whoThrows: 'Fraud protection' },
  INSUFFICIENT_FUNDS: { description: 'Your card does not have enough funds for the transaction.', whoThrows: 'Bank' },
  INTERNAL_ERROR: { description: "Wyre's internal error code.", whoThrows: 'Wyre' },
  INVALID_BILLING_ADDRESS: { description: 'Invalid billing address for the card', whoThrows: 'Bank' },
  INVALID_CARD_AUTHENTICATION: { description: 'Invalid  card authentication code (CVV)', whoThrows: 'Bank' },
  INVALID_CARD_NUMBER: { description: 'Invalid card number', whoThrows: 'Bank' },
  INVALID_CARD_TOKEN: { description: 'Invalid card', whoThrows: 'Bank' },
  INVALID_IDENTITY_FOUND: { description: 'No valid identity found', whoThrows: 'Wyre' },
  INVALID_PAYMENT_INFORMATION: {
    description: 'Invalid card payment information (billing address, phone...)',
    whoThrows: 'Bank',
  },
  INVALID_TRANSACTION: { description: 'Invalid transaction', whoThrows: 'Bank' },
  LIMIT_RESTRICTIONS: { description: 'Card limit restrictions', whoThrows: 'Bank' },
  LOCATION_NOT_SUPPORTED: {
    description: 'Geo location (country or state) not supported by W… or card not allowed to be used in that location.',
    whoThrows: 'Wyre and Bank',
  },
  LOST_STOLEN_CARD: { description: 'Card reported as lost or stolen', whoThrows: 'Bank' },
  NO_IDENTITY_FOUND: { description: 'No identity found', whoThrows: 'Wyre' },
  NO_PHONE_FOUND: { description: 'No phone found for user', whoThrows: 'Wyre and Bank' },
  NO_SMS_CONFIRMATION: { description: 'No SMS confirmation', whoThrows: 'Wyre' },
  ORDER_ALREADY_BEEN_PAID: { description: 'Order was already been paid', whoThrows: 'Wyre' },
  ORDER_AMOUNT_TOO_HIGH: { description: 'Payment processor. Card spend limit exceeded.', whoThrows: 'Bank' },
  ORDER_EXPIRED: { description: 'Order has expired', whoThrows: 'Wyre' },
  ORDER_LOOKS_BAD: { description: 'Fraud protection', whoThrows: 'Fraud protection' },
  PAYMENT_SCORE_ABUSE: { description: 'Fraud protection', whoThrows: 'Fraud protection' },
  PROCESSING_TIMEOUT: { description: 'Order processing timeout', whoThrows: 'Wyre' },
  SECURITY_VIOLATION: { description: 'Fraud protection', whoThrows: 'Fraud protection' },
  SSN_ERROR: { description: 'Invalid SSN', whoThrows: 'Fraud protection' },
  SUSPECTED_FRAUD: { description: 'Fraud protection', whoThrows: 'Fraud protection' },
  TRANSACTION_NOT_PERMITTED_BY_BANK: { description: 'Transaction not permitted by the issuer bank', whoThrows: 'Bank' },
  TRANSACTION_REJECTED_BY_BANK: { description: 'Transaction rejected by the issuer bank', whoThrows: 'Bank' },
  UNKNOWN_ERROR: { description: 'Uncategorized or unexpected error', whoThrows: 'Wyre' },
}

export function wyreUnifiedErrorCodeMapByWhoThrows(map1: WyreUnifiedErrorCodeMap) {
  let map2: any = {}

  for (const error in map1) {
    // const whoThrows = map1[error].whoThrows
    if (map2.whoThrows) {
      map2.whoThrows.error = map1[error].description
    } else {
      map2.whoThows = {}
      map2.whoThrows.error = map1[error].description
    }
  }
  return map2
}
