import Box from '@mui/material/Box'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '../Autocomplete'
import countries from './data/countries.json'

type CountrySelectProps = Omit<ComponentProps<typeof Autocomplete>, 'options' | 'label'>

function CountrySelect(props: CountrySelectProps) {
  const { t } = useTranslation()
  return (
    <Autocomplete
      label={t('country')}
      id="country-select"
      sx={{ width: '100%' }}
      options={countries}
      renderOption={(props, { label, key, phone }) => (
        <Box component="li" sx={{ '& > img': { mr: 4, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${key.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${key.toLowerCase()}.png 2x`}
            alt=""
          />
          {label} ({key}) +{phone}
        </Box>
      )}
      {...props}
    />
  )
}

export default CountrySelect
