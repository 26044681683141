import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, CircularProgress, Box } from '@mui/material'
import Claim from './Claim'
import { SonarService } from '../services/SonarService'
import Constants from '../utils/constants'
import { getClaimIdFromClaimLink } from '../utils/claimlinks'
import { claimIdFromUrlState, seriesState } from '../store'
import { useRecoilState } from 'recoil'
import { responsiveTypography } from '../utils/styles'
import NoTokensRemain from './NoTokensRemain'
import NFTHeader from './NFTHeader'
const { SONAR_API_KEY } = Constants

interface DirectClaimProps {
  campaign: Campaign
  title: string
}
const DirectClaim = ({ campaign, title }: DirectClaimProps) => {
  const { t } = useTranslation()
  const [claimIdFromURL] = useRecoilState(claimIdFromUrlState)
  const [series] = useRecoilState(seriesState)
  const [claimId, setClaimId] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [isSoldOut, setIsSoldOut] = useState<boolean>(false)

  useEffect(() => {
    // User already has a claimId via a wallet redirect
    if (claimIdFromURL) {
      setClaimId(claimIdFromURL)
      return
    }

    // Request a claimlink and get the claimId from it
    const fetchClaimLink = async () => {
      setIsLoading(true)
      try {
        const res = await SonarService.getClaimLink(campaign.id, SONAR_API_KEY)
        if (res.claimLink) {
          const id = getClaimIdFromClaimLink(res.claimLink)
          setClaimId(id)
        } else {
          if (res.headers.get('content-type') === 'application/json') {
            const json = await res.json()
            if (json.error.toLowerCase().includes('insufficient supply')) {
              setError(t('errors.nftSoldOut'))
              setIsSoldOut(true)
            }
          }
        }
      } catch (e) {
        setError(t('errors.tryAgain'))
      }
      setIsLoading(false)
    }
    fetchClaimLink()
  }, [claimIdFromURL, campaign.id, t])

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{ maxWidth: 340, width: '100%' }}
    >
      <NFTHeader title={title} description={series?.metadata.description} />

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flex: 1,
            justifyContent: {
              xs: 'center',
              sm: 'flex-start',
            },
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : null}

      {claimId ? <Claim campaign={campaign} claimId={claimId} /> : null}

      {error ? (
        isSoldOut ? (
          <NoTokensRemain title={title} />
        ) : (
          <Typography variant="body1" component="p" sx={responsiveTypography()}>
            {error}
          </Typography>
        )
      ) : null}
    </Grid>
  )
}

export default DirectClaim
