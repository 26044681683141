import useReCaptchaVerification from '../../hooks/useReCaptchaVerification'
import { useTranslation, Trans } from 'react-i18next'
import ReCaptchaMessage from './ReCaptchaMessage'
import { CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { responsiveTypography } from '../../utils/styles'
import ButtonContainer from '../ButtonContainer'
import NearIcon from '../../icons/NearIcon'
import colors from '../../theme/colors'

interface ClaimNFTProps {
  handleClaimNFT: () => void
  handleConnectWallet: () => void
  error: string
  isLoading: boolean
  accountId: string
}

const ClaimNFT = ({ handleClaimNFT, handleConnectWallet, error, isLoading, accountId }: ClaimNFTProps) => {
  const { t } = useTranslation()
  const xs = useMediaQuery('(max-width: 600px)')
  const { isCaptchaLoading, captchaSuccess, handleFraudPreventEventWrapper } = useReCaptchaVerification()

  function gradient() {
    return {
      background: 'linear-gradient(84.31deg, #4667E3 1.41%, #2CC4F4 83.57%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
    }
  }

  return (
    <>
      {!captchaSuccess ? (
        <ReCaptchaMessage />
      ) : (
        <>
          {isLoading ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '100%',
                flex: 1,
                minHeight: {
                  xs: '200px',
                  sm: '262px',
                },
              }}
            >
              <CircularProgress color="secondary" sx={{ display: 'flex', flex: 1 }} />
              <Typography
                variant="body1"
                component="p"
                sx={{ ...responsiveTypography(), mt: 2, textAlign: 'center', flex: 1 }}
              >
                {t('claimLoading')}
              </Typography>
            </Grid>
          ) : (
            <>
              <Typography variant="h1" component="h1" sx={{ ...responsiveTypography(), wordBreak: 'break-all' }}>
                <Trans i18nKey="walletConnected" values={{ id: accountId }}>
                  Wallet <br />
                  <span style={gradient()}>{{ accountId }}</span>
                  <br /> Connected
                </Trans>
              </Typography>
              <Typography variant="body1" component="p" sx={responsiveTypography()}>
                {error ? error : t('canNowClaim')}
              </Typography>
            </>
          )}
          <ButtonContainer>
            <LoadingButton
              loading={isLoading || isCaptchaLoading}
              loadingPosition="start"
              variant="contained"
              color="secondary"
              size={xs ? 'small' : 'medium'}
              onClick={() => handleFraudPreventEventWrapper(handleClaimNFT, 'claim_nft')}
              startIcon={<NearIcon />}
              disabled={error.length > 0}
              sx={{
                mb: {
                  xs: 0,
                  sm: 2,
                },
                flexGrow: 1,
                flex: 1,
                mr: {
                  xs: '4px',
                  sm: 0,
                },
              }}
            >
              {t('claimNft')}
            </LoadingButton>
            <LoadingButton
              loading={isCaptchaLoading}
              loadingPosition="start"
              variant="outlined"
              color="secondary"
              size={xs ? 'small' : 'medium'}
              onClick={() => handleFraudPreventEventWrapper(handleConnectWallet, 'connect_wallet')}
              startIcon={<NearIcon color={colors.SECONDARY} />}
              sx={{
                flexGrow: 1,
                flex: 1,
                ml: {
                  xs: '4px',
                  sm: 0,
                },
              }}
            >
              {t('changeWallet')}
            </LoadingButton>
          </ButtonContainer>
        </>
      )}
    </>
  )
}

export default ClaimNFT
