import { useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { SonarService } from '../services/SonarService'
import constants from '../utils/constants'
import { Button, Grid, Typography, Box, TextField, Link, Checkbox } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Check as CheckIcon, ArrowForward as ArrowForwardIcon, ThumbUp as ThumbUpIcon } from '@mui/icons-material'
import Claim from './Claim'
import { getClaimIdFromClaimLink } from '../utils/claimlinks'
import { claimIdFromUrlState, seriesState } from '../store'
import { useRecoilState } from 'recoil'
import { responsiveTypography } from '../utils/styles'
import NFTHeader from './NFTHeader'

interface EmailStepperProps {
  campaign: Campaign
  title: string
}

export default function EmailStepper({ campaign, title }: EmailStepperProps) {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const [claimIdFromUrl] = useRecoilState(claimIdFromUrlState)
  const [series] = useRecoilState(seriesState)

  // STEP 1: user submits EMAIL
  const [email, setEmail] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [emailReceived, setEmailReceived] = useState(false)
  const areTermsRequired = useMemo(() => campaign?.requirements.terms, [campaign])
  const [didAcceptTerms, setDidAcceptTerms] = useState<boolean>(false)
  const trimmedEmail = useMemo(() => email.trim().toLowerCase(), [email])
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  // STEP 2: user submits 6-DIGIT VERIFICATION CODE
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [resendingCode, setResendingCode] = useState<boolean>(false)
  const [codeResent, setCodeResent] = useState<boolean>(false)
  const [codeVerified, setCodeVerified] = useState<boolean>(false)

  // STEP 3: user claims NFT via CLAIMLINK
  const [claimId, setClaimId] = useState<string | undefined>()

  useEffect(() => {
    if (emailReceived) {
      setActiveStep(1)
    }
    if (codeVerified) {
      setActiveStep(2)
    }
    if (claimIdFromUrl) {
      setClaimId(claimIdFromUrl)
      setActiveStep(2)
    }
  }, [emailReceived, codeVerified, claimIdFromUrl, areTermsRequired, didAcceptTerms])

  const handleSubmitEmail = async ({
    onSuccess,
    onError,
  }: {
    onSuccess: () => void
    onError: (msg: string) => void
  }) => {
    if (isFormValid) {
      setIsSubmitting(true)
      let response = await SonarService.generateCode(campaign.id, trimmedEmail, 600000, constants.SONAR_API_KEY)

      if (!response.ok) {
        const text = await response.text()
        console.error('error submitting email: ', text || `${response.status}: ${response.statusText}`)
        onError('Oops, something went wrong. Please try again.')
      } else {
        onSuccess()
      }
    } else {
      onError('Please enter a valid email.')
    }
  }

  const handleAcceptedTerms = useCallback((didAccept) => {
    setDidAcceptTerms(didAccept)
  }, [])

  const handleValidateCode = async () => {
    if (!code) {
      setError('Please enter your 6-digit code')
    } else {
      setIsSubmitting(true)
      try {
        let res = await SonarService.validateCode(code, constants.SONAR_API_KEY, campaign, trimmedEmail)
        if (res.status === 500) {
          setError(t('errors.tryAgain'))
        } else if (res.status === 400) {
          setError(t('errors.invalidCode'))
        } else {
          const { claimLink } = res as { claimLink: string }
          const id = getClaimIdFromClaimLink(claimLink)
          setClaimId(id)
          setCodeVerified(true)
          // window.location.href = claimLink
        }
      } catch (e) {
        setError(t('errors.invalidCode'))
      }
      setIsSubmitting(false)
    }
  }

  const handleResendCode = () => {
    if (!resendingCode) {
      setResendingCode(true)
      handleSubmitEmail({
        onSuccess: () => {
          setCodeResent(true)
          setIsSubmitting(false)
          window.setTimeout(() => {
            setResendingCode(false)
            setCodeResent(false)
          }, 5000)
        },
        onError: (msg: string) => {
          setError(msg)
          setIsSubmitting(false)
          setResendingCode(false)
        },
      })
    }
  }

  useEffect(() => {
    const [isEmailValid] =
      email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) || []
    const isTermsValid = !areTermsRequired || (areTermsRequired && didAcceptTerms)
    const isFormValid = Boolean(isEmailValid && isTermsValid)
    setIsFormValid(isFormValid)
  }, [email, didAcceptTerms, areTermsRequired])

  return (
    <>
      <Box sx={{ maxWidth: 340, width: '100%' }}>
        <NFTHeader title={title} description={series?.metadata.description} />

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          <Step>
            <StepLabel>
              <Typography variant="body1" component="span">
                {t('email')}
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="body1" component="span">
                {t('verify')}
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="body1" component="span">
                {t('claim')}
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>

        <Grid container>
          {/*  EMAIL */}
          {activeStep === 0 && (
            <Grid container sx={{ mb: 2, width: '100%' }}>
              <Typography variant="h1" component="h1" sx={responsiveTypography()}>
                {t('enterYourEmail')}
              </Typography>

              <TextField
                autoFocus
                id="email-input"
                label={t('email')}
                variant="filled"
                onFocus={() => {
                  if (error) setError('')
                }}
                error={error.length === 0 ? false : true}
                helperText={error.length === 0 ? null : error}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                fullWidth
                InputProps={{ disableUnderline: true }}
                sx={{ mb: 4 }}
              />

              <Grid item xs={12}>
                <Typography variant="body1" component="p" sx={responsiveTypography()}>
                  {t('sendCodeToEmail')}
                </Typography>
              </Grid>

              {areTermsRequired && (
                <Grid item xs={12} mb={4}>
                  <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column" justifyContent="center" mr={1}>
                      <Checkbox
                        checked={didAcceptTerms}
                        onChange={(event) => handleAcceptedTerms(event.target.checked)}
                      />
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="body2" sx={responsiveTypography(0)}>
                        <Trans i18nKey="agreementAccept">
                          I agree with
                          <Link href={campaign.termsLink!} variant="body2" target="blank" rel="noreferer nofollow">
                            Terms & Conditions
                          </Link>
                          for "{{ title }}"
                        </Trans>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}

              <LoadingButton
                loading={isSubmitting}
                loadingPosition="end"
                variant="contained"
                color="secondary"
                disabled={!isFormValid}
                onClick={(e) => {
                  e.preventDefault()
                  handleSubmitEmail({
                    onSuccess: () => {
                      setEmailReceived(true)
                      setIsSubmitting(false)
                    },
                    onError: (msg: string) => {
                      setError(msg)
                      setIsSubmitting(false)
                    },
                  })
                }}
                endIcon={<ArrowForwardIcon />}
              >
                {t('submit')}
              </LoadingButton>
            </Grid>
          )}

          {/* 6-DIGIT VERIFICATION CODE */}
          {activeStep === 1 && (
            <Grid container sx={{ mb: 2 }}>
              <Typography variant="h1" component="h1" sx={responsiveTypography(3)}>
                {t('enterCode')}
              </Typography>

              <TextField
                autoFocus
                id="code-input"
                label={t('code')}
                variant="filled"
                type="number"
                onFocus={() => {
                  if (error) setError('')
                }}
                error={error.length === 0 ? false : true}
                helperText={error.length === 0 ? null : error}
                onChange={(e) => {
                  if (e.target.value.length <= 6) setCode(e.target.value)
                }}
                fullWidth
                InputProps={{ disableUnderline: true }}
                sx={{ mb: 3 }}
              />

              <Typography variant="body1" component="p" sx={responsiveTypography()}>
                {t('codeWillExpire')}
              </Typography>

              {error && (
                <div style={{ marginTop: '1em' }} className="error">
                  {error}
                </div>
              )}

              <LoadingButton
                loading={isSubmitting}
                loadingPosition="end"
                disabled={codeVerified}
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  handleValidateCode()
                }}
                endIcon={codeVerified ? <ThumbUpIcon /> : <CheckIcon />}
                sx={{ mb: 2 }}
              >
                {codeVerified ? t('verified') : t('verify')}
              </LoadingButton>

              <Button
                variant="outlined"
                color="secondary"
                disabled={codeResent}
                onClick={(e) => {
                  e.preventDefault()
                  handleResendCode()
                }}
              >
                {codeResent ? t('sentNewCode', { email }) : t('resendCode')}
              </Button>
            </Grid>
          )}

          {/* CLAIM */}
          {activeStep === 2 && claimId && <Claim campaign={campaign} claimId={claimId} />}
        </Grid>
      </Box>
    </>
  )
}
