import { createTheme } from '@mui/material/styles'
import colors from './colors'

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
    },
    secondary: {
      main: colors.SECONDARY,
    },
  },
  typography: {
    fontFamily: 'Manrope-Regular',
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paper': {
            borderRadius: '8px',
          },
          '.MuiDialogActions-root': {
            '>:not(:first-of-type)': {
              marginLeft: '16px !important',
            },
          },
          '.MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '.Mui-active': {
            color: `${colors.SECONDARY} !important`,
          },
          '.Mui-completed': {
            color: `${colors.SECONDARY} !important`,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: '32px',
          paddingBottom: '24px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          boxShadow: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope-Regular',
          color: colors.SECONDARY,
          textDecorationColor: colors.SECONDARY,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.SECONDARY,
        },
        h1: {
          fontFamily: 'Archivo',
          fontWeight: 800,
          fontSize: '40px',
          lineHeight: '110%',
          '@media (max-width: 600px)': {
            fontSize: '26px',
          },
        },
        h2: {
          fontFamily: 'Manrope-Regular',
          fontWeight: 700,
          fontSize: '20px',
          color: colors.SECONDARY,
        },
        h3: {
          fontFamily: 'Manrope-Regular',
          fontWeight: 700,
          fontSize: '16px',
          color: colors.SECONDARY,
        },
        h4: {
          fontFamily: 'Manrope-Regular',
          fontWeight: 700,
          fontSize: '28px',
          color: colors.SECONDARY,
        },
        h5: {
          fontFamily: 'Manrope-Regular',
          fontWeight: 500,
          fontSize: '16px',
          color: colors.SECONDARY,
        },
        body1: {
          fontFamily: 'Manrope-Regular',
          fontSize: '16px',
          lineHeight: '160%',
          color: colors.SECONDARY,
          '@media (max-width: 600px)': {
            fontSize: '14px',
          },
        },
        subtitle1: {
          fontFamily: 'Manrope-Bold',
          fontSize: '18px',
          color: colors.SECONDARY,
          '@media (max-width: 600px)': {
            fontSize: '16px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontSize: '19px',
          color: 'rgba(38, 38, 38, 0.56)',
          '& label.Mui-focused': {
            color: 'rgba(38, 38, 38, 0.56)',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: '1px solid transparent',
          borderRadius: '8px',
          backgroundColor: 'rgba(38, 38, 38, 0.08)',
          '&:after': {
            borderBottom: '2px solid transparent',
          },
          '&:before': {
            borderBottom: '1px solid transparent',
          },
          '&:hover': {
            border: '1px solid rgba(38, 38, 38, 0.16)',
            backgroundColor: 'rgba(38, 38, 38, 0.08)',
            '&:not(.Mui-disabled)': {
              '&:before': {
                borderBottom: '1px solid transparent',
              },
            },
          },
          '&.Mui-focused': {
            borderColor: 'rgba(38, 38, 38, 0.32)',
          },
          '&.Mui-error': {
            backgroundColor: 'rgba(219, 85, 85, 0.08)',
          },
          '& label.Mui-focused': {
            color: 'rgba(38, 38, 38, 0.56)',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        // disableRipple: true, // No more ripple!
      },
      styleOverrides: {
        root: {
          borderRadius: '56px',
          padding: '16px 24px',
          fontSize: '16px',
          fontFamily: 'Manrope-Regular',
          fontWeight: 'bold',
          letterSpacing: '0.01em',
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.PRIMARY,
            border: `1px solid ${colors.PRIMARY}`,
            ':hover': {
              color: colors.PRIMARY_HOVER,
              border: `1px solid ${colors.PRIMARY_HOVER_BG}`,
              backgroundColor: colors.PRIMARY_HOVER_BG,
            },
            ':active': {
              color: colors.PRIMARY_ACTIVE,
              border: `1px solid ${colors.PRIMARY_ACTIVE_BG}`,
              backgroundColor: colors.PRIMARY_ACTIVE_BG,
            },
            ':disabled': {
              color: colors.PRIMARY_DISABLED,
              border: `1px solid ${colors.PRIMARY_DISABLED_BG}`,
              backgroundColor: colors.PRIMARY_DISABLED_BG,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: colors.SECONDARY,
            border: `1px solid ${colors.SECONDARY}`,
            ':hover': {
              color: colors.SECONDARY_HOVER,
              border: `1px solid ${colors.SECONDARY_HOVER_BG}`,
              backgroundColor: colors.SECONDARY_HOVER_BG,
            },
            ':active': {
              color: colors.SECONDARY_ACTIVE,
              border: `1px solid ${colors.SECONDARY_ACTIVE_BG}`,
              backgroundColor: colors.PRIMARY_ACTIVE_BG,
            },
            ':disabled': {
              color: colors.SECONDARY_DISABLED,
              border: `1px solid ${colors.SECONDARY_DISABLED_BG}`,
              backgroundColor: colors.SECONDARY_DISABLED_BG,
            },
          },
        },

        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            color: colors.SECONDARY,
            borderColor: colors.SECONDARY,
            backgroundColor: 'transparent',

            ':hover': {
              color: colors.SECONDARY_HOVER_BG,
              borderColor: colors.SECONDARY_HOVER_BG,
              backgroundColor: 'transparent',
            },
            ':active': {
              color: colors.SECONDARY_ACTIVE_BG,
              borderColor: colors.SECONDARY_ACTIVE_BG,
              backgroundColor: 'transparent',
            },
            ':disabled': {
              color: colors.SECONDARY_DISABLED_BG,
              borderColor: colors.SECONDARY_DISABLED_BG,
              backgroundColor: 'transparent',
            },
          },
        },

        {
          props: { size: 'large' },
          style: {
            height: '56px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: '56px',
            '@media (max-width: 600px)': {
              minHeight: 'none',
              height: '48px',
              fontSize: '14px',
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: 'unset',
            minHeight: '40px',
            fontSize: '13px',
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      ],
    },
  },
})
