import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { seriesState } from '../store'
import { responsiveTypography } from '../utils/styles'
import ButtonContainer from './ButtonContainer'

interface LetsGoProps {
  campaign: Campaign
  title: string
  handleShowIntro: () => void
}
const LetsGo = ({ campaign, title, handleShowIntro }: LetsGoProps) => {
  const { t } = useTranslation()
  const description = getDescription(campaign)
  const [series] = useRecoilState(seriesState)

  function getDescription(campaign: Campaign) {
    if (campaign.requirements?.qr) {
      return t('campaignRequirements.qr')
    } else if (campaign.requirements?.email) {
      return t('campaignRequirements.email')
    } else if (campaign.requirements?.socials?.tiktok) {
      return t('campaignRequirements.tiktok')
    } else if (campaign.requirements?.socials?.twitter) {
      return t('campaignRequirements.twitter')
    } else if (campaign.requirements?.fiatPayment) {
      return t('campaignRequirements.fiatPayment')
    }
    return ''
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{ maxWidth: 340, width: '100%' }}
    >
      <Typography variant="subtitle1" component="h1" sx={responsiveTypography()}>
        {t('youGetNft')}
      </Typography>
      <Typography variant="h1" component="h1" sx={{ ...responsiveTypography(), wordBreak: 'break-word' }}>
        {title}
      </Typography>
      <Typography variant="body1" component="p" sx={responsiveTypography()}>
        {series?.metadata?.description}
      </Typography>
      <Typography variant="subtitle1" component="p" sx={responsiveTypography()}>
        {description}
      </Typography>

      <ButtonContainer>
        <Button
          variant="contained"
          color="secondary"
          size={'medium'}
          onClick={handleShowIntro}
          sx={{ flexGrow: 1, flex: 1 }}
        >
          {t('letsGo')}
        </Button>
      </ButtonContainer>
    </Grid>
  )
}

export default LetsGo
