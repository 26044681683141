import { Grid } from '@mui/material'

interface ButtonContainerProps {
  children: JSX.Element | JSX.Element[]
}
function ButtonContainer({ children }: ButtonContainerProps) {
  return (
    <Grid
      container
      sx={{
        p: {
          xs: 2,
          sm: 0,
        },
        backgroundColor: {
          xs: '#f6f6f6',
          sm: 'transparent',
        },
        flexDirection: {
          xs: 'row',
          sm: 'column',
        },
        position: {
          xs: 'fixed',
          sm: 'static',
        },
        bottom: {
          xs: 0,
          sm: 'unset',
        },
        left: {
          xs: 0,
          sm: 'unset',
        },
        justifyContent: {
          xs: 'center',
          sm: 'start',
        },
        alignItems: {
          xs: 'start',
          sm: 'start',
        },
      }}
    >
      {children}
    </Grid>
  )
}

export default ButtonContainer
