import { utils, connect, keyStores, WalletConnection, providers } from 'near-api-js'
import { JsonRpcProvider } from 'near-api-js/lib/providers'
import { SetterOrUpdater } from 'recoil'
import { DEFAULT_NETWORK_ID } from '..'
import { NearState } from '../../store/models/near'
import { TransactionStatus } from '../../store/models/transaction'

export const SATORI_NEAR_ACCOUNT = DEFAULT_NETWORK_ID === 'mainnet' ? 'snft.near' : 'snft.testnet'

export const config = {
  networkId: DEFAULT_NETWORK_ID,
  nodeUrl: `https://rpc.${DEFAULT_NETWORK_ID}.near.org`,
  archivalNodeUrl: `https://archival-rpc.${DEFAULT_NETWORK_ID}.near.org`,
  walletUrl: `https://wallet${DEFAULT_NETWORK_ID === 'testnet' ? '.testnet' : ''}.near.org`,
  helperUrl: `https://helper.${DEFAULT_NETWORK_ID}.near.org`,
  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
}

export const provider = new providers.JsonRpcProvider(config.nodeUrl)
export const archivalProvider = new providers.JsonRpcProvider(config.archivalNodeUrl)

export async function getAccount(accountId: string) {
  let valid = /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+$/.test(accountId)
  let exists = false
  // console.log('valid? ', valid)
  if (!valid) return { exists, valid }

  //@ts-ignore
  const near = await connect(config)
  const account = await near.account(accountId)

  try {
    const state = await account.state()
    const hasContract = state.code_hash !== '11111111111111111111111111111111'
    return { valid, exists: true, state, hasContract, account }
  } catch (e) {
    //@ts-ignore
    if (/does not exist/.test(e.toString())) {
      exists = false
      //@ts-ignore
    } else if (/is invalid/.test(e.toString())) {
      valid = false
    } else {
      throw e
    }
  }
  return { exists, valid }
}

export async function signIn(wallet: WalletConnection, redirectPath: string) {
  wallet.requestSignIn(
    SATORI_NEAR_ACCOUNT, //contract requesting access
    'Creator Studio', //optional name
    //@ts-ignore
    window.location.origin + redirectPath //optional URL to redirect to if the sign in was successful
    // TODO: add optional URL to redirect to if the sign in was NOT successful
  )
}

export async function getSignedInAccountId() {
  //@ts-ignore
  const near = await connect(config)
  //@ts-ignore
  const wallet = new WalletConnection(near)
  const walletAccountId = wallet.getAccountId()
  return walletAccountId
}

export async function sendTokens(userWallet: WalletConnection, nearAmount: string) {
  if (!nearAmount) throw new Error('Near amount cannot be empty')
  //@ts-ignore
  const account = userWallet.account()
  const yoctoAmt = utils.format.parseNearAmount(nearAmount)
  try {
    account.sendMoney(
      SATORI_NEAR_ACCOUNT, // receiver account
      yoctoAmt // amount in yoctoNEAR
    )
  } catch (e) {
    console.log('error sending NEAR: ', e)
  }
}

export async function initNear(setNear?: SetterOrUpdater<NearState>) {
  //@ts-ignore
  const near = await connect(config)
  //@ts-ignore
  const wallet = new WalletConnection(near)
  if (setNear) setNear({ wallet })
  return wallet
}

export const logout = (near: NearState) => {
  near.wallet?.signOut()
}

export const getTransactionStatus = (txHash: string, senderId: string) => {
  async function getState(provider: JsonRpcProvider, txHash: string, accountId: string) {
    const result = await provider.txStatus(txHash, accountId)
    return result
  }

  return getState(archivalProvider, txHash, senderId)
}

export const getTransactionInformation = async (transactionHash: string) => {
  const txRes = (await archivalProvider.sendJsonRpc('EXPERIMENTAL_tx_status', [transactionHash, 'foo'])) as any
  let receiverId, signerId, amount, status: TransactionStatus | undefined
  const receipt = txRes.receipts?.filter((r: any) => r.receiver_id === SATORI_NEAR_ACCOUNT)[0]
  if (receipt) {
    const transferAction = receipt.receipt?.Action
    const internalAction = transferAction.actions.filter((a: any) => 'Transfer' in a)[0]
    if (transferAction) {
      signerId = transferAction.signer_id
      receiverId = receipt.receiver_id
      amount = internalAction.Transfer?.deposit
      status = txRes.status?.SuccessValue === '' ? 'success' : 'error'
    }
  }
  return { receiverId, signerId, amount, status }
}

/**
 * Call a contract view method without being signed into a NEAR account
 * https://github.com/near/near-api-js/blob/master/examples/cookbook/utils/get-state.js
 * @param account_id contract address
 * @param method_name
 * @param args {arg_name: value}
 * @returns
 */
export async function callViewMethod(account_id: string, method_name: string, args: any) {
  const rawResult = await provider.query({
    request_type: 'call_function',
    account_id,
    method_name,
    args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
    finality: 'optimistic',
  })
  //@ts-ignore
  return JSON.parse(Buffer.from(rawResult.result).toString())
}

export const nearView = async ({
  contractId,
  methodName,
  args,
  account,
}: {
  contractId: string
  methodName: string
  args: any
  account: any
}) => {
  try {
    return await account.viewFunction(contractId, methodName, args)
  } catch (e) {
    return { error: JSON.stringify(e), args, account }
  }
}
