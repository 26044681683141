import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { responsiveTypography } from '../utils/styles'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <div className="App" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h1" component="h1" sx={{ ...responsiveTypography(), textAlign: 'center' }}>
          {t('errors.pageNotFound')}
        </Typography>
        <Typography variant="h2" component="h2" sx={{ ...responsiveTypography(), textAlign: 'center' }}>
          {t('errors.checkUrl')}
        </Typography>
      </Grid>
    </div>
  )
}

export default NotFound
