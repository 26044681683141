import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { paymentClaimState, paymentFormState } from '../../../store'
import { launchConfetti } from '../../../utils/claimlinks'
import Receipt from '../Receipt'

// Wyre Order COMPLETE, NFT minted to {accountId}
function ReceiptStep() {
  const { email } = useRecoilValue(paymentFormState)
  const { paymentMethodName, sourceAmount, transferId, status, txHash } = useRecoilValue(paymentClaimState)

  useEffect(() => launchConfetti(), [])

  return (
    <Receipt
      paymentMethodName={paymentMethodName}
      sourceAmount={sourceAmount}
      email={email}
      transferId={transferId}
      status={status}
      txHash={txHash}
    />
  )
}

export default ReceiptStep
