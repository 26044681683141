import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import TextField from '../TextField'

interface AccountFormProps {
  accountId: string | undefined
}

function AccountForm({ accountId }: AccountFormProps) {
  const { t } = useTranslation()
  return (
    <Grid container item direction="row" justifyContent="space-between" spacing={3}>
      <Grid item xs={12}>
        <TextField id="accountId" label={t('nearAccount')} value={accountId} disabled={true} />
      </Grid>
    </Grid>
  )
}

export default AccountForm
