import { Grid, Button, SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BlockExplorer } from '../../icons/BlockExplorer'
import explorer from '../../utils/explorer'
import colors from '../../theme/colors'
import { config } from '../../services/near'
import ButtonContainer from '../ButtonContainer'
import useMediaQuery from '@mui/material/useMediaQuery'

interface ClaimNFTProps {
  txHash: string
}

const Claimed = ({ txHash }: ClaimNFTProps) => {
  const { t } = useTranslation()
  const xs = useMediaQuery('(max-width:600px)')

  function BlockExplorerIcon({ color = '#FFF' }: { color?: string }) {
    return (
      <SvgIcon sx={{ mt: '6px' }}>
        <BlockExplorer color={colors.SECONDARY} />
      </SvgIcon>
    )
  }

  return (
    <>
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ mb: 4 }}>
        <img src="/claimed-check.png" style={{ height: xs ? '40px' : '73px' }} alt="success"></img>
      </Grid>
      <Typography
        variant="h1"
        component="h1"
        sx={{ mb: { xs: 2, sm: 4 }, flex: 1, width: '100%', textAlign: 'center' }}
      >
        {t('claimed')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{ mb: { xs: 3, sm: 7 }, flex: 1, width: '100%', textAlign: 'center' }}
      >
        {t('claimedCongrats')}
      </Typography>

      <ButtonContainer>
        <Button
          variant="contained"
          color="secondary"
          size={xs ? 'small' : 'medium'}
          onClick={() => window.open(`${config.walletUrl}/?tab=collectibles`, '_blank')}
          sx={{
            mb: {
              xs: 0,
              sm: 2,
            },
            flexGrow: 1,
            flex: 1,
            mr: {
              xs: '4px',
              sm: 0,
            },
          }}
        >
          {t('viewInWallet')}
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          size={xs ? 'small' : 'medium'}
          onClick={() => window.open(explorer.getTxURL(txHash), '_blank')}
          startIcon={xs ? null : <BlockExplorerIcon />}
          sx={{
            flexGrow: 1,
            flex: 1,
            ml: {
              xs: '4px',
              sm: 0,
            },
            '&:hover': { color: colors.SECONDARY_HOVER_BG },
          }}
        >
          {t('viewTransaction')}
        </Button>
      </ButtonContainer>
    </>
  )
}

export default Claimed
