import TextField from '@mui/material/TextField'
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete'
import { FormHelperText } from '@mui/material'

type AutocompleteProps = Omit<MuiAutocompleteProps<any, false, true, undefined>, 'renderInput'> & {
  label: string
  options: ({ key: string; label: string; suggested?: boolean } & Omit<any, 'key' | 'label'>)[]
  error?: undefined | string
}

function Autocomplete({
  id,
  label,
  error,
  autoHighlight = true,
  disableClearable = true,
  getOptionLabel = ({ label }) => label,
  isOptionEqualToValue = ({ key }, value) => key === value.key,
  ...props
}: AutocompleteProps) {
  return (
    <>
      <MuiAutocomplete
        id={id}
        sx={{ width: '100%', '.MuiFormControl-root': { marginBottom: 0 } }}
        autoHighlight={autoHighlight}
        disableClearable={disableClearable}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="filled"
            size="small"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            sx={{ mb: 3 }}
          />
        )}
        {...props}
      />
      <FormHelperText id={`${id}-error`} error>
        {error}
      </FormHelperText>
    </>
  )
}

export default Autocomplete
