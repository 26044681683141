import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { SonarService } from '../services/SonarService'
import constants from '../utils/constants'
import { Grid, Typography, Box, TextField, SvgIcon } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Claim from './Claim'
import { getClaimIdFromClaimLink } from '../utils/claimlinks'
import { claimIdFromUrlState, seriesState } from '../store'
import { useRecoilState } from 'recoil'
import { responsiveTypography } from '../utils/styles'
import NFTHeader from './NFTHeader'

interface TikTokStepperProps {
  campaign: Campaign
  title: string
}

export default function TikTokStepper({ campaign, title }: TikTokStepperProps) {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const [claimIdFromUrl] = useRecoilState(claimIdFromUrlState)
  const [series] = useRecoilState(seriesState)

  const [error, setError] = useState<string>('')
  const [handle, setHandle] = useState<string>('')
  const [handleSent, setHandleSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [claimId, setClaimId] = useState<string | undefined>()

  useEffect(() => {
    if (handleSent) {
      setActiveStep(1)
    }
    if (claimIdFromUrl) {
      setClaimId(claimIdFromUrl)
      setActiveStep(1)
    }
    // eslint-disable-next-line
  }, [handleSent])

  const handleVerifyHandle = async () => {
    setIsSubmitting(true)
    const trimmed = handle.trim().replaceAll('@', '')
    try {
      const res = await SonarService.tiktokVerifyDisplayName(campaign.id, trimmed, constants.SONAR_API_KEY)
      if (res.claimLink) {
        setHandleSent(true)
        const id = getClaimIdFromClaimLink(res.claimLink)
        setClaimId(id)
      } else {
        setError(t('errors.invalidTiktokHandle'))
        setIsSubmitting(false)
      }
    } catch (e) {
      // get claim link anyway
      const res = await SonarService.getClaimLink(campaign.id, constants.SONAR_API_KEY)
      if (res.claimLink) {
        const id = getClaimIdFromClaimLink(res.claimLink)
        setClaimId(id)
        setHandleSent(true)
      } else {
        let error = t('errors.tryAgain')
        if (res.headers.get('content-type') === 'application/json') {
          const json = await res.json()
          if (json.error.toLowerCase().includes('insufficient supply')) {
            error = t('errors.nftSoldOut')
          }
        }
        setError(error)
        setIsSubmitting(false)
      }
    }
  }

  const TikTokIcon = () => {
    return (
      <SvgIcon>
        <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.0952 0H1.90476C0.854476 0 0 0.854476 0 1.90476V14.0952C0 15.1455 0.854476 16 1.90476 16H14.0952C15.1455 16 16 15.1455 16 14.0952V1.90476C16 0.854476 15.1455 0 14.0952 0ZM12.5737 6.98019C12.4872 6.98819 12.3996 6.99352 12.3109 6.99352C11.3116 6.99352 10.4335 6.47962 9.92267 5.70286C9.92267 7.74057 9.92267 10.059 9.92267 10.0979C9.92267 11.8918 8.46819 13.3463 6.67429 13.3463C4.88038 13.3463 3.4259 11.8918 3.4259 10.0979C3.4259 8.304 4.88038 6.84952 6.67429 6.84952C6.7421 6.84952 6.80838 6.85562 6.87505 6.85981V8.46057C6.80838 8.45257 6.74286 8.44038 6.67429 8.44038C5.75848 8.44038 5.01638 9.18248 5.01638 10.0983C5.01638 11.0141 5.75848 11.7562 6.67429 11.7562C7.5901 11.7562 8.39886 11.0347 8.39886 10.1189C8.39886 10.0827 8.41486 2.65448 8.41486 2.65448H9.94476C10.0888 4.02248 11.1931 5.1021 12.5737 5.20114V6.98019Z"
            fill="white"
          />
        </svg>
      </SvgIcon>
    )
  }

  return (
    <Box sx={{ maxWidth: 340, width: '100%' }}>
      <NFTHeader title={title} description={series?.metadata.description} />

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        <Step>
          <StepLabel>
            <Typography variant="body1" component="span">
              {t('tiktok')}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography variant="body1" component="span">
              {t('claim')}
            </Typography>
          </StepLabel>
        </Step>
      </Stepper>

      <Grid container>
        {/*  TIK TOK */}
        {activeStep === 0 && (
          <Grid container sx={{ mb: 2, width: '100%' }}>
            <Typography variant="h1" component="h1" sx={responsiveTypography()}>
              {t('enterTiktokHandle')}
            </Typography>

            <TextField
              autoFocus
              id="tiktok-input"
              label={t('tiktokHandle')}
              variant="filled"
              onFocus={() => {
                if (error) setError('')
              }}
              error={error.length === 0 ? false : true}
              helperText={error.length === 0 ? null : error}
              onChange={(e) => {
                setHandle(e.target.value)
              }}
              fullWidth
              InputProps={{ disableUnderline: true }}
              sx={{ mb: 3 }}
            />

            <Typography variant="body1" component="p" sx={responsiveTypography()}></Typography>

            <LoadingButton
              loading={isSubmitting}
              loadingPosition="start"
              variant="contained"
              color="secondary"
              onClick={handleVerifyHandle}
              startIcon={<TikTokIcon />}
            >
              {t('submit')}
            </LoadingButton>
          </Grid>
        )}

        {/* CLAIM */}
        {activeStep === 1 && claimId && <Claim campaign={campaign} claimId={claimId} />}
      </Grid>
    </Box>
  )
}
