import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// these should match the LANGUAGES constant in constants.ts
import en from './locales/en.json'
import es from './locales/es.json'

i18n
  .use(LanguageDetector) // detects the browser language and injects the language to use
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.NODE_ENV !== 'production',
    resources: {
      // these should match the LANGUAGES constant in constants.ts
      en: { translation: en },
      es: { translation: es },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
