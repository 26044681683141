import { Grid, Button, SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { responsiveTypography } from '../../utils/styles'
import ButtonContainer from '../ButtonContainer'
import colors from '../../theme/colors'
import useMediaQuery from '@mui/material/useMediaQuery'
import { BlockExplorer } from '../../icons/BlockExplorer'
import { config } from '../../services/near'
import explorer from '../../utils/explorer'

interface ReceiptProps {
  paymentMethodName: string | undefined
  sourceAmount: string | undefined
  email: string
  transferId: string | undefined
  status: string | undefined
  txHash: string | undefined
}

function Receipt({ paymentMethodName, sourceAmount, email, transferId, status, txHash }: ReceiptProps) {
  const { t } = useTranslation()
  const xs = useMediaQuery('(max-width:600px)')

  function BlockExplorerIcon({ color = '#FFF' }: { color?: string }) {
    return (
      <SvgIcon sx={{ mt: '6px' }}>
        <BlockExplorer color={colors.SECONDARY} />
      </SvgIcon>
    )
  }

  return (
    <Grid container direction="column" sx={{ mb: 2 }}>
      <Typography variant="h1" component="h1" sx={responsiveTypography(3)}>
        {t('success')}
      </Typography>
      <Typography variant="body1" component="p" sx={responsiveTypography(3)}>
        {t('cardChargedBody', {
          name: paymentMethodName,
          amount: sourceAmount ? parseInt(sourceAmount as string).toFixed(2) : '',
          email,
        })}
      </Typography>
      <Typography variant="body2" component="p" sx={responsiveTypography(3)}>
        {t('receiptId', { id: transferId })}
        <br />
        {t('transferStatus', { id: status })}
        <br />
        {t('blockchainTx', { id: txHash })}
      </Typography>
      <ButtonContainer>
        <Button
          variant="contained"
          color="secondary"
          size={xs ? 'small' : 'medium'}
          onClick={() => window.open(`${config.walletUrl}/?tab=collectibles`, '_blank')}
          sx={{
            mb: {
              xs: 0,
              sm: 2,
            },
            flexGrow: 1,
            flex: 1,
            mr: {
              xs: '4px',
              sm: 0,
            },
          }}
        >
          {t('viewInWallet')}
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          size={xs ? 'small' : 'medium'}
          onClick={() => {
            if (txHash) window.open(explorer.getTxURL(txHash), '_blank')
          }}
          startIcon={xs ? null : <BlockExplorerIcon />}
          sx={{
            flexGrow: 1,
            flex: 1,
            ml: {
              xs: '4px',
              sm: 0,
            },
            '&:hover': { color: colors.SECONDARY_HOVER_BG },
          }}
        >
          {t('viewTransaction')}
        </Button>
      </ButtonContainer>
    </Grid>
  )
}

export default Receipt
