import { useRecoilState, useSetRecoilState } from 'recoil'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { WyreExceptionResponse } from '../../../services/models/wyre'
import { SonarService } from '../../../services/SonarService'
import {
  accountIdState,
  isMintableState,
  linkdropResIdState,
  paymentAuthorizationState,
  paymentClaimState,
  paymentFormState,
  paymentStepState,
} from '../../../store'
import {
  setCityError,
  setCountryError,
  setEmailError,
  setFormError,
  setIsSubmitting,
  setPhoneError,
  setPostalCodeError,
  setStateError,
  setStreetError,
} from '../../../store/actions/payment/paymentForm'
import { setIsAuthorizing } from '../../../store/actions/payment/paymentAuthorization'
import constants from '../../../utils/constants'
import { getWyreException, WyreFormElement } from '../../../utils/wyre'
import CardForm from '../CardForm'
import { setClaim } from '../../../store/actions/payment/paymentClaim'
import { setIsAuthorized } from '../../../store/actions/payment/paymentStep'
import {
  setGivenName,
  setFamilyName,
  setPhone,
  setEmail,
  setCity,
  setCountry,
  setPostalCode,
  setState,
  setStreet,
} from '../../../store/actions/payment/paymentForm'
import { checkSupplyRemains } from '../../../utils/nft'

export const LS_GIVEN_NAME = 'GIVEN_NAME'
export const LS_FAMILY_NAME = 'FAMILY_NAME'
export const LS_PHONE = 'PHONE'
export const LS_EMAIL = 'EMAIL'
export const LS_STREET = 'STREET'
export const LS_CITY = 'CITY'
export const LS_STATE = 'STATE'
export const LS_POSTAL_CODE = 'POSTAL_CODE'
export const LS_COUNTRY_KEY = 'COUNTRY_KEY'
export const LS_COUNTRY_LABEL = 'COUNTRY_LABEL'
export const LS_COUNTRY_PHONE = 'COUNTRY_PHONE'

function ProcessorStep({ campaign, title }: { campaign: Campaign; title: string }) {
  const { t } = useTranslation()
  const setIsMintable = useSetRecoilState(isMintableState)
  const setPaymentAuthorizationState = useSetRecoilState(paymentAuthorizationState)
  const setPaymentClaimState = useSetRecoilState(paymentClaimState)
  const [{ totalPrice }, setPaymentStepState] = useRecoilState(paymentStepState)
  const [linkdropResId] = useRecoilState(linkdropResIdState)
  const [accountId] = useRecoilState(accountIdState)
  const [
    {
      givenName,
      familyName,
      phone,
      email,
      street,
      city,
      state,
      postalCode,
      country,
      paymentToken,
      expiryMonth,
      expiryYear,
    },
    setPaymentState,
  ] = useRecoilState(paymentFormState)

  // LOCAL STORAGE METHODS
  const setLocalStorageItems = useCallback(() => {
    console.log('SETTING LOCAL STORAGE ITEMS...')
    if (givenName) localStorage.setItem(LS_GIVEN_NAME, givenName)
    if (familyName) localStorage.setItem(LS_FAMILY_NAME, familyName)
    if (phone) localStorage.setItem(LS_PHONE, phone)
    if (email) localStorage.setItem(LS_EMAIL, email)
    if (street) localStorage.setItem(LS_STREET, street)
    if (city) localStorage.setItem(LS_CITY, city)
    if (state) localStorage.setItem(LS_STATE, state)
    if (postalCode) localStorage.setItem(LS_POSTAL_CODE, postalCode)
    if (country) {
      localStorage.setItem(LS_COUNTRY_KEY, country.key)
      localStorage.setItem(LS_COUNTRY_LABEL, country.label)
      localStorage.setItem(LS_COUNTRY_PHONE, country.phone)
    }
    if (country) console.log('localStorage', localStorage)
  }, [givenName, familyName, phone, email, street, city, state, postalCode, country])

  const clearLocalStorageItems = useCallback(() => {
    console.log('CLEARING LOCAL STORAGE ITEMS...')
    localStorage.removeItem(LS_GIVEN_NAME)
    localStorage.removeItem(LS_FAMILY_NAME)
    localStorage.removeItem(LS_PHONE)
    localStorage.removeItem(LS_EMAIL)
    localStorage.removeItem(LS_STREET)
    localStorage.removeItem(LS_CITY)
    localStorage.removeItem(LS_STATE)
    localStorage.removeItem(LS_POSTAL_CODE)
    localStorage.removeItem(LS_COUNTRY_KEY)
    localStorage.removeItem(LS_COUNTRY_LABEL)
    localStorage.removeItem(LS_COUNTRY_PHONE)
    console.log('localStorage', localStorage)
  }, [])

  // STEP 2A - Submit Order
  const handleSubmit = useCallback(async () => {
    // Check if NFTs remain - tokens could have been minted since last check at page load
    const [, supplyRemains] = await checkSupplyRemains(campaign)
    setIsMintable(supplyRemains)

    let body: any = {
      price: totalPrice,
      givenName,
      familyName,
      phone,
      email,
      address: {
        street1: street,
        city,
        state,
        postalCode,
        country: country.key,
      },
      payment_method_token: paymentToken, // sensitive CC data is tokenized by third-party service Spreedly
      month: expiryMonth,
      year: expiryYear,
      trigger3ds: true,
      redirectUrl3ds: window.location.href,
      accountId,
    }
    if (linkdropResId) body.linkdropResId = linkdropResId
    console.log('linkdropResId  ', linkdropResId)
    console.log('order body     ', body)
    setLocalStorageItems()

    try {
      setIsSubmitting(true, setPaymentState)
      const res = await SonarService.createOrder(campaign.id, body, constants.SONAR_API_KEY)
      setIsSubmitting(false, setPaymentState)
      console.log('order res', res)

      if (res.ok === false) {
        const exception = (await res.json()) as WyreExceptionResponse
        const [error, element] = getWyreException(exception)

        switch (element) {
          case WyreFormElement.City:
            setCityError(error, setPaymentState)
            break
          case WyreFormElement.Country:
            setCountryError(error, setPaymentState)
            break
          case WyreFormElement.Phone:
            setPhoneError(error, setPaymentState)
            break
          case WyreFormElement.Email:
            setEmailError(error, setPaymentState)
            break
          case WyreFormElement.Street:
            setStreetError(error, setPaymentState)
            break
          case WyreFormElement.State:
            setStateError(error, setPaymentState)
            break
          case WyreFormElement.PostalCode:
            setPostalCodeError(error, setPaymentState)
            break
          case WyreFormElement.Form:
            setFormError(error, setPaymentState)
            break
          case WyreFormElement.None:
            // noop
            break
          default:
            setFormError('An unknown error occurred', setPaymentState)
            break
        }

        return
      }

      const { authorization3dsUrl } = res
      // TODO: set to local storage
      window.location.href = authorization3dsUrl
    } catch (e) {
      console.error(e)
      setFormError('An unknown error occurred', setPaymentState)
    }
  }, [
    campaign,
    setIsMintable,
    city,
    country.key,
    email,
    expiryMonth,
    expiryYear,
    familyName,
    givenName,
    paymentToken,
    phone,
    postalCode,
    setPaymentState,
    state,
    street,
    totalPrice,
    accountId,
    linkdropResId,
    setLocalStorageItems,
  ])

  // STEP 2B - Authorize Order
  useEffect(() => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    // searchParams.forEach((v, k) => console.log(`${k}:    ${v}`))

    if (
      searchParams.has('paymentMethodName') &&
      searchParams.get('id') !== null &&
      (searchParams.get('status') === 'PROCESSING' || searchParams.get('status') === 'COMPLETE')
    ) {
      const walletOrderId = searchParams.get('id') as string

      console.log('POPULATING STATE FROM LOCAL STORAGE...')
      console.log('localStorage', localStorage)
      const lsGivenName = localStorage.getItem(LS_GIVEN_NAME)
      const lsFamilyName = localStorage.getItem(LS_FAMILY_NAME)
      const lsPhone = localStorage.getItem(LS_PHONE)
      const lsEmail = localStorage.getItem(LS_EMAIL)
      const lsStreet = localStorage.getItem(LS_STREET)
      const lsCity = localStorage.getItem(LS_CITY)
      const lsState = localStorage.getItem(LS_STATE)
      const lsPostalCode = localStorage.getItem(LS_POSTAL_CODE)
      const lsCountryKey = localStorage.getItem(LS_COUNTRY_KEY)
      const lsCountryLabel = localStorage.getItem(LS_COUNTRY_LABEL)
      const lsCountryPhone = localStorage.getItem(LS_COUNTRY_PHONE)
      if (lsGivenName) setGivenName(lsGivenName, setPaymentState)
      if (lsFamilyName) setFamilyName(lsFamilyName, setPaymentState)
      if (lsPhone) setPhone(lsPhone, setPaymentState)
      if (lsEmail) setEmail(lsEmail, setPaymentState)
      if (lsStreet) setStreet(lsStreet, setPaymentState)
      if (lsCity) setCity(lsCity, setPaymentState)
      if (lsState) setState(lsState, setPaymentState)
      if (lsPostalCode) setPostalCode(lsPostalCode, setPaymentState)
      if (lsCountryKey && lsCountryLabel && lsCountryPhone)
        setCountry(
          {
            key: lsCountryKey,
            label: lsCountryLabel,
            phone: lsCountryPhone,
          },
          setPaymentState
        )
      handleAuthorize(walletOrderId)
    }
    // eslint-disable-next-line
  }, [])

  // Wallet Order Quotation
  // useEffect(() => {
  //   async function getWalletOrderQuotation() {
  //     let body: any = {
  //       price,
  //     }
  //     try {
  //       const res = await SonarService.createQuotation(campaign.id, body, constants.SONAR_API_KEY)
  //       console.log('walletOrderQuotation', res)
  //     } catch (e) {
  //       console.error(e)
  //     }
  //   }

  //   getWalletOrderQuotation()
  // }, [campaign.id, price])

  const handleAuthorize = useCallback(
    async (walletOrderId: string) => {
      try {
        setIsAuthorizing({ isAuthorizing: true }, setPaymentAuthorizationState)
        const res = await SonarService.authorizeOrder(
          campaign.id,
          {
            walletOrderId,
          },
          constants.SONAR_API_KEY
        )
        console.log('auth res', res)

        if (res.ok === false) {
          const { status, statusText } = res
          setFormError(`${status} ${statusText}`, setPaymentState)
          return
        }

        if (res.status !== 'COMPLETE') {
          setFormError(t('errors.cannotProcessCard', { status: res.status }), setPaymentState)
          return
        }

        // Authorization Response
        /* returns:
          - Wyre
              - orderId (transferId?)
              - status
              - paymentMethodName
              - sourceAmount 
          - Near 
              - txId
              - status
                - SUCCESS
                  - Near accountId 
                - FAIL
                  - refundId
       */
        const { paymentMethodName, sourceAmount, status, transferId, txHash } = res as any
        setClaim({ paymentMethodName, sourceAmount, status, transferId, txHash }, setPaymentClaimState)
        clearLocalStorageItems()
        setIsAuthorized(setPaymentStepState)
      } catch (e) {
        console.error(e)
        setFormError(t('errors.generic'), setPaymentState)
      } finally {
        setIsAuthorizing({ isAuthorizing: false }, setPaymentAuthorizationState)
      }
    },
    [
      campaign.id,
      setPaymentAuthorizationState,
      setPaymentClaimState,
      setPaymentState,
      setPaymentStepState,
      clearLocalStorageItems,
      t,
    ]
  )

  return (
    <CardForm
      onSubmit={handleSubmit}
      price={campaign.requirements.fiatPayment?.price!}
      title={title}
      accountId={accountId}
    />
  )
}

export default ProcessorStep
