import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import { responsiveTypography } from '../utils/styles'

interface InactiveProps {
  campaign: Campaign
  title: string
}
const Inactive = ({ campaign, title }: InactiveProps) => {
  const { t } = useTranslation()
  const start = moment(campaign.startTime).format('LLL')
  const end = moment(campaign.endTime).format('LLL')
  const isEnded = campaign.startTime - new Date().getTime() < 0 ? true : false

  return (
    <Grid container spacing={0} direction="column" alignItems="start" justifyContent="start" sx={{ maxWidth: 340 }}>
      <Typography variant="subtitle1" component="h1" sx={responsiveTypography()}>
        {title}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          mb: 4,
          justifyContent: {
            xs: 'center',
            sm: 'flex-start',
          },
        }}
      >
        <img src="/inactive-error.png" style={{ height: '73px' }} alt="success"></img>
      </Grid>
      <Typography variant="h1" component="h1" sx={responsiveTypography()}>
        {isEnded ? t('noLongerAvailable') : t('comeBackSoon')}
      </Typography>
      <Typography variant="body1" component="p" sx={responsiveTypography()}>
        {t('nftAvailDates')}
      </Typography>
      <Typography variant="body1" component="p" sx={responsiveTypography()}>
        <Trans i18nKey="dateToDate" values={{ date1: start, date2: end }} />
      </Typography>
    </Grid>
  )
}

export default Inactive
