import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Grid, useMediaQuery } from '@mui/material'
import { SonarService } from '../services/SonarService'
import Constants from '../utils/constants'
import NotFound from './NotFound'
import Email from '../components/Email'
import TikTok from '../components/Tiktok'
// import Twitter from '../components/Twitter'
import Inactive from '../components/Inactive'
import DirectClaim from '../components/DirectClaim'
import Footer from '../components/Footer'
import { campaignState, claimIdFromUrlState, isMintableState, seriesState } from '../store'
import { useRecoilState } from 'recoil'
import CheckIn from '../components/CheckIn'
import Payment from '../components/Payment/Payment'
import FileViewer from '../components/FileViewer'
import NoTokensRemain from '../components/NoTokensRemain'
import { checkSupplyRemains } from '../utils/nft'
import LanguageSelect from '../components/LanguageSelect'

const { SONAR_API_KEY } = Constants

/**
 * A landing page to distribute NFTs
 *
 * Workflow
 * 1. A user fulfills a distribution {campaign}'s reqs, receiving a {claimId}
 * 2. A {claimId} is a token that allows a user to mint an NFT
 *
 * in order to deliver the NFT,
 * a user may need to create or sign in to a NEAR wallet.
 * in which case they will be redirected to the NEAR wallet site.
 * upon redirect back from wallet site, {claimId} will appear as a
 * URL query param to persist state of the workflow
 *
 * when landing:                        /{campaignId}
 * 
 * ALL CAMPAIGNS (except CC):
 * STEP 1: fulfill req
 * STEP 2: specify {accountId}
 *    when redirected from wallet:      /{campaignId}/{claimId}?account_id={accountId}
 * 
 * CC PAYMENT CAMPAIGN ONLY
 * STEP 1: specify {accountId}
 *    when redirected from wallet:      /{campaignId}?account_id={accountId}
 * STEP 2: fulfill req
 *    when redirected from 3ds:         /{campaignId}?account_id={accountId}&success=true
 
 */
function Landing() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [campaign, setCampaign] = useRecoilState(campaignState)
  const [, setSeries] = useRecoilState(seriesState)
  const [error, setError] = useState<string>('')
  const [title, setTitle] = useState('')
  const [isCampaignActive, setIsCampaignActive] = useState(true)
  const [, setClaimIdFromUrl] = useRecoilState(claimIdFromUrlState)
  const [isMintable, setIsMintable] = useRecoilState(isMintableState)
  const xs = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    function parseURLForClaimId() {
      const claimIdFromURL = window.location.pathname.split('/')[2]?.split('?')[0]
      setClaimIdFromUrl(claimIdFromURL)
    }

    parseURLForClaimId()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function fetchCampaign() {
      const id = window.location.pathname.split('/')[1]
      if (!id) {
        setLoading(false)
        return
      }
      try {
        let res = await SonarService.getCampaign(id, SONAR_API_KEY)
        if ('ok' in res && !res.ok) return

        try {
          // INCREMENT PAGE VIEWS FOR CAMPAIGN
          if (!window.location.href.includes('redirect')) {
            await SonarService.incrementCampaignViews(id, SONAR_API_KEY)
          }
        } catch (e) {
          console.warn(e)
        } finally {
          // LOAD CONTENT
          const campaign = res as Campaign
          setCampaign(campaign)
          setTitle(campaign.seriesId.split('/').pop() as string)
          updateMetaContent(campaign)
          // VALIDATE CAMPAIGN AND NFT SUPPLY
          checkCampaignIsActive(campaign.startTime, campaign.endTime)
          const [nftRes, isSupplyRemaining] = await checkSupplyRemains(campaign)
          setSeries(nftRes)
          setIsMintable(isSupplyRemaining)
        }
      } catch (e) {
        console.error('error: ', JSON.stringify(e))
        setError(t('errors.tryAgain'))
      } finally {
        setLoading(false)
      }
    }
    fetchCampaign()
  }, [setCampaign, setIsMintable, t, setSeries])

  // Set NFT image on <meta> tags for previews
  function updateMetaContent(campaign: Campaign) {
    if (!campaign.media) return
    ;['og', 'twitter'].forEach((tag) => {
      const el = document.createElement('meta')
      el.setAttribute('property', `${tag}:image`)
      el.setAttribute('content', `https://ipfs.io/ipfs/${campaign.media}?filename=img.jpg`)
      document.head.insertBefore(el, document.querySelector(`meta[property="${tag}:description"]`))
    })
  }

  function checkCampaignIsActive(startTime: number, endTime: number | undefined) {
    if (Date.now() < startTime) {
      setIsCampaignActive(false)
    }
    if (endTime) {
      if (Date.now() > endTime) {
        setIsCampaignActive(false)
      }
    }
  }

  if (loading) {
    return null
  }
  if (!campaign) {
    return <NotFound />
  }

  const isPaymentCampaign = campaign.requirements?.fiatPayment
  const isCheckInCampaign = campaign.requirements?.qr
  const isEmailCampaign = campaign.requirements?.email
  const isTiktokCampaign = campaign.requirements?.socials?.tiktok
  const isDirectCampaign = !(isPaymentCampaign || isCheckInCampaign || isEmailCampaign || isTiktokCampaign)

  return (
    <Container maxWidth="xl" sx={{ minHeight: '100%' }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="start"
        justifyContent="start"
        sx={{ flex: 1, height: '100%' }}
      >
        {error ? (
          <div className="centered">{error}</div>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              flex: 1,
              mt: {
                xs: 2,
                sm: 10,
              },
            }}
          >
            <Grid container direction="column" alignItems="end">
              <LanguageSelect sx={{ width: { xs: 'initial', sm: 'initial' } }} />
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="start"
              justifyContent="start"
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                mt: {
                  xs: 3,
                  sm: 0,
                },
              }}
            >
              {/* LEFT */}
              <Grid
                container
                direction="column"
                sx={{
                  flex: 1,
                  mr: {
                    xs: 0,
                    sm: 2,
                    md: 5,
                  },
                }}
              >
                {campaign.media && (
                  <Grid
                    container
                    direction="row"
                    alignItems="start"
                    justifyContent="end"
                    sx={{
                      width: '100%',
                      justifyContent: {
                        xs: 'start',
                        sm: 'end',
                      },
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '90%',
                          md: '75%',
                        },
                        marginBottom: {
                          xs: 3,
                          sm: 6,
                        },
                      }}
                    >
                      <FileViewer
                        style={{
                          width: xs ? '230px' : '100%',
                          height: 'auto',
                          borderRadius: '16px',
                        }}
                        fromIPFS
                        mediaID={campaign.media}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {/* RIGHT */}
              <Grid
                container
                direction="column"
                sx={{
                  flex: 1,
                  ml: {
                    xs: 0,
                    sm: 2,
                    md: 5,
                  },
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="start"
                  justifyContent="start"
                  sx={{
                    width: '100%',
                    justifyContent: {
                      xs: 'center',
                      sm: 'start',
                    },
                    mb: {
                      xs: 12,
                      sm: 12,
                    },
                  }}
                >
                  {isCampaignActive ? (
                    isMintable ? (
                      <>
                        {isPaymentCampaign && <Payment campaign={campaign} title={title} />}
                        {isCheckInCampaign && <CheckIn campaign={campaign} title={title} />}
                        {isEmailCampaign && <Email campaign={campaign} title={title} />}
                        {isTiktokCampaign && <TikTok campaign={campaign} title={title} />}
                        {isDirectCampaign && <DirectClaim campaign={campaign} title={title} />}
                      </>
                    ) : (
                      <NoTokensRemain title={title} showTitle />
                    )
                  ) : (
                    <Inactive campaign={campaign} title={title} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Footer />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default Landing
