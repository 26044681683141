import { useCallback } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '../TextField'
import CountrySelect from './CountrySelect'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { paymentFormState } from '../../store'
import {
  setCity,
  setCityValidity,
  setCountry,
  setEmail,
  setEmailValidity,
  setFamilyName,
  setFamilyNameValidity,
  setGivenName,
  setGivenNameValidity,
  setPhone,
  setPhoneValidity,
  setPostalCode,
  setPostalCodeValidity,
  setState,
  setStateValidity,
  setStreet,
  setStreetValidity,
} from '../../store/actions/payment/paymentForm'
import { CountryOption } from './models/common'

function UserDetailsForm() {
  const { t } = useTranslation()
  const [
    {
      phone,
      phoneError,
      email,
      emailError,
      street,
      streetError,
      city,
      cityError,
      state,
      stateError,
      postalCode,
      postalCodeError,
      country,
      givenName,
      givenNameError,
      familyName,
      familyNameError,
      isSubmitting,
    },
    setPaymentState,
  ] = useRecoilState(paymentFormState)

  const handlePhoneChange = useCallback((value: string) => setPhone(value, setPaymentState), [setPaymentState])
  const handlePhoneValidityChange = useCallback(
    (value: boolean) => setPhoneValidity(value, setPaymentState),
    [setPaymentState]
  )
  const handleEmailChange = useCallback((value: string) => setEmail(value, setPaymentState), [setPaymentState])
  const handleEmailValidityChange = useCallback(
    (value: boolean) => setEmailValidity(value, setPaymentState),
    [setPaymentState]
  )
  const handleStreetChange = useCallback((value: string) => setStreet(value, setPaymentState), [setPaymentState])
  const handleStreetValidityChange = useCallback(
    (value: boolean) => setStreetValidity(value, setPaymentState),
    [setPaymentState]
  )
  const handleCityChange = useCallback((value: string) => setCity(value, setPaymentState), [setPaymentState])
  const handleCityValidityChange = useCallback(
    (value: boolean) => setCityValidity(value, setPaymentState),
    [setPaymentState]
  )
  const handleStateChange = useCallback((value: string) => setState(value, setPaymentState), [setPaymentState])
  const handleStateValidityChange = useCallback(
    (value: boolean) => setStateValidity(value, setPaymentState),
    [setPaymentState]
  )
  const handlePostalCodeChange = useCallback(
    (value: string) => setPostalCode(value, setPaymentState),
    [setPaymentState]
  )
  const handlePostalCodeValidityChange = useCallback(
    (value: boolean) => setPostalCodeValidity(value, setPaymentState),
    [setPaymentState]
  )
  const handleCountryChange = useCallback(
    (_: any, value: CountryOption) => setCountry(value, setPaymentState),
    [setPaymentState]
  )
  const handleGivenNameChange = useCallback((value: string) => setGivenName(value, setPaymentState), [setPaymentState])
  const handleGivenNameValidityChange = useCallback(
    (value: boolean) => setGivenNameValidity(value, setPaymentState),
    [setPaymentState]
  )
  const handleFamilyNameChange = useCallback(
    (value: string) => setFamilyName(value, setPaymentState),
    [setPaymentState]
  )
  const handleFamilyNameValidityChange = useCallback(
    (value: boolean) => setFamilyNameValidity(value, setPaymentState),
    [setPaymentState]
  )

  return (
    <Grid container item direction="row" justifyContent="space-between" spacing={3}>
      <Grid item xs={6}>
        <TextField
          id="first-name"
          label={t('firstName')}
          value={givenName}
          onChange={handleGivenNameChange}
          onValidityChange={handleGivenNameValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={givenNameError}
          inputProps={{ inputMode: 'text', pattern: '[a-zA-Z]*' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="last-name"
          label={t('lastName')}
          value={familyName}
          onChange={handleFamilyNameChange}
          onValidityChange={handleFamilyNameValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={familyNameError}
          inputProps={{ inputMode: 'text', pattern: '[a-zA-Z]*' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="phone"
          label={t('phone')}
          value={phone}
          onChange={handlePhoneChange}
          onValidityChange={handlePhoneValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={phoneError}
          inputProps={{ inputMode: 'tel', pattern: '/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="email"
          label={t('email')}
          value={email}
          onChange={handleEmailChange}
          onValidityChange={handleEmailValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={emailError}
          inputProps={{ inputMode: 'text', pattern: '[a-zA-Z]*' }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="street"
          label={t('address')}
          value={street}
          onChange={handleStreetChange}
          onValidityChange={handleStreetValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={streetError}
          inputProps={{ inputMode: 'text' }}
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          id="city"
          label={t('city')}
          value={city}
          onChange={handleCityChange}
          onValidityChange={handleCityValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={cityError}
          inputProps={{ inputMode: 'text' }}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          id="state"
          label={t('state')}
          value={state}
          onChange={handleStateChange}
          onValidityChange={handleStateValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={stateError}
          inputProps={{ inputMode: 'text', pattern: '[a-zA-Z]*' }}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          id="postal-code"
          label={t('postalCode')}
          value={postalCode}
          onChange={handlePostalCodeChange}
          onValidityChange={handlePostalCodeValidityChange}
          validate={(value: string) => Boolean(value && value.length > 0)}
          disabled={isSubmitting}
          helperText={postalCodeError}
          inputProps={{ inputMode: 'text' }}
        />
      </Grid>
      <Grid item xs={7}>
        <CountrySelect value={country} onChange={handleCountryChange} />
      </Grid>
    </Grid>
  )
}

export default UserDetailsForm
