import { SetterOrUpdater } from 'recoil'
import { CountryOption } from '../../../components/Payment/models/common'
import { PaymentFormState } from '../../models/payment'

const validateForm = ({
  isPhoneValid,
  isEmailValid,
  isStreetValid,
  isCityValid,
  isStateValid,
  isPostalCodeValid,
  isGivenNameValid,
  isFamilyNameValid,
  isAgreed,
  paymentToken,
  formError,
  ...state
}: PaymentFormState) => ({
  ...state,
  isPhoneValid,
  isEmailValid,
  isStreetValid,
  isCityValid,
  isStateValid,
  isPostalCodeValid,
  isGivenNameValid,
  isFamilyNameValid,
  isAgreed,
  paymentToken,
  formError,
  isFormValid:
    isPhoneValid &&
    isEmailValid &&
    isStreetValid &&
    isCityValid &&
    isStateValid &&
    isPostalCodeValid &&
    isGivenNameValid &&
    isFamilyNameValid &&
    isAgreed &&
    !formError &&
    typeof paymentToken === 'string',
})

const setPhone = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, phone: value, phoneError: undefined, formError: undefined }))

const setPhoneError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, phoneError: value }))

const setPhoneValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isPhoneValid: value }))

const setEmail = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, email: value, emailError: undefined, formError: undefined }))

const setEmailError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, emailError: value }))

const setEmailValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isEmailValid: value }))

const setStreet = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, street: value, streetError: undefined, formError: undefined }))

const setStreetError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, streetError: value }))

const setStreetValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isStreetValid: value }))

const setCity = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, city: value, cityError: undefined, formError: undefined }))

const setCityError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, cityError: value }))

const setCityValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isCityValid: value }))

const setState = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, state: value, stateError: undefined, formError: undefined }))

const setStateError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, stateError: value }))

const setStateValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isStateValid: value }))

const setPostalCode = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, postalCode: value, postalCodeError: undefined, formError: undefined }))

const setPostalCodeError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, postalCodeError: value }))

const setPostalCodeValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isPostalCodeValid: value }))

const setCountry = (value: CountryOption, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, country: value, formError: undefined }))

const setCountryError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, countryError: value }))

const setGivenName = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, givenName: value, givenNameError: undefined, formError: undefined }))

const setGivenNameError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, givenNameError: value }))

const setGivenNameValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isGivenNameValid: value }))

const setFamilyName = (value: string, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, familyName: value, familyNameError: undefined, formError: undefined }))

const setFamilyNameError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, familyNameError: value }))

const setFamilyNameValidity = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isFamilyNameValid: value }))

const setPayment = (
  nextState: Pick<PaymentFormState, 'expiryMonth' | 'expiryYear' | 'paymentToken'>,
  set: SetterOrUpdater<PaymentFormState>
) => set((state) => validateForm({ ...state, ...nextState, formError: undefined }))

const setIsAgreed = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => validateForm({ ...state, isAgreed: value }))

const setIsSubmitting = (value: boolean, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, isSubmitting: value }))

const setFormError = (value: string | undefined, set: SetterOrUpdater<PaymentFormState>) =>
  set((state) => ({ ...state, isFormValid: false, formError: value }))

export {
  setPhone,
  setPhoneError,
  setPhoneValidity,
  setEmail,
  setEmailError,
  setEmailValidity,
  setStreet,
  setStreetError,
  setStreetValidity,
  setCity,
  setCityError,
  setCityValidity,
  setState,
  setStateError,
  setStateValidity,
  setPostalCode,
  setPostalCodeError,
  setPostalCodeValidity,
  setCountry,
  setCountryError,
  setGivenName,
  setGivenNameError,
  setGivenNameValidity,
  setFamilyName,
  setFamilyNameError,
  setFamilyNameValidity,
  setPayment,
  setIsAgreed,
  setIsSubmitting,
  setFormError,
}
