import { Grid, Link } from '@mui/material'

function Footer() {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        pt: 4,
        pb: 4,
        display: {
          xs: 'none',
          sm: 'flex',
        },
      }}
    >
      <Link href="https://satori.art">
        <img src="/made-by-satori.png" alt="Made by Satori" style={{ width: '162px' }}></img>
      </Link>
      <Link
        href="/"
        sx={{
          display: {
            xs: 'none',
            sm: 'block',
          },
        }}
      >
        <img src="/cs-logo-footer.png" alt="Creator Studio logo" style={{ width: '141px' }}></img>
      </Link>
      <Link href="https://near.org/">
        <img src="/built-on-near.png" alt="Built on NEAR" style={{ width: '213px' }}></img>
      </Link>
    </Grid>
  )
}

export default Footer
