import { Typography } from '@mui/material'
import React from 'react'
import { responsiveTypography } from '../utils/styles'

interface NFTHeaderProps {
  title: string
  description: string | undefined
}

function NFTHeader({ title, description }: NFTHeaderProps) {
  return (
    <>
      <Typography
        variant="subtitle1"
        component="h1"
        sx={{ ...responsiveTypography(0), fontSize: '10px !important', mb: 0 }}
      >
        NFT Details
      </Typography>
      <Typography variant="subtitle1" component="h1" sx={{ ...responsiveTypography(), mb: 0 }}>
        {title}
      </Typography>
      {description ? (
        <>
          <Typography variant="body1" component="p" sx={responsiveTypography()}>
            {description}
          </Typography>
        </>
      ) : null}
    </>
  )
}

export default NFTHeader
