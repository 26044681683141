import { Grid, Typography } from '@mui/material'
import useReCaptchaVerification from '../../hooks/useReCaptchaVerification'
import { useTranslation } from 'react-i18next'
import ReCaptchaMessage from './ReCaptchaMessage'
import LoadingButton from '@mui/lab/LoadingButton'
import { responsiveTypography } from '../../utils/styles'
import colors from '../../theme/colors'
import ButtonContainer from '../ButtonContainer'
import useMediaQuery from '@mui/material/useMediaQuery'
import NearIcon from '../../icons/NearIcon'

interface ConnectYourWalletProps {
  handleCreateWallet: () => void
  handleConnectWallet: () => void
  error: string
  isLoading: boolean
  disableLinkdrop?: boolean
}

const ConnectYourWallet = ({
  handleCreateWallet,
  handleConnectWallet,
  error,
  isLoading,
  disableLinkdrop = true,
}: ConnectYourWalletProps) => {
  const { t } = useTranslation()
  const xs = useMediaQuery('(max-width:600px)')
  const { isCaptchaLoading, captchaSuccess, handleFraudPreventEventWrapper } = useReCaptchaVerification()

  return (
    <>
      {!captchaSuccess ? (
        <ReCaptchaMessage />
      ) : (
        <Grid container direction="column">
          <Typography variant="h1" component="h1" sx={responsiveTypography()}>
            {t('connectYourWallet')}
          </Typography>
          <Typography variant="body1" component="p" sx={responsiveTypography()}>
            {error ? error : t('connectWalletBody')}
          </Typography>
          <ButtonContainer>
            {!disableLinkdrop ? (
              /**
              <LoadingButton
                loading={isLoading || isCaptchaLoading}
                loadingPosition="start"
                variant="contained"
                color="secondary"
                size={xs ? 'small' : 'medium'}
                onClick={() => handleFraudPreventEventWrapper(handleCreateWallet, 'create_wallet')}
                startIcon={<NearIcon />}
                sx={{
                  mb: {
                    xs: 0,
                    sm: 2,
                  },
                  flexGrow: 1,
                  flex: 1,
                  mr: {
                    xs: '4px',
                    sm: 0,
                  },
                }}
              >
                {t('createWallet')}
              </LoadingButton>
              **/
              <></>
            ) : (
              <></>
            )}
            <LoadingButton
              loading={isCaptchaLoading}
              loadingPosition="start"
              variant="outlined"
              color="secondary"
              size={xs ? 'small' : 'medium'}
              onClick={() => handleFraudPreventEventWrapper(handleConnectWallet, 'connect_wallet')}
              startIcon={xs ? null : <NearIcon color={colors.SECONDARY} />}
              sx={{
                flexGrow: 1,
                flex: 1,
                ml: {
                  xs: '4px',
                  sm: 0,
                },
              }}
            >
              {t('connectWallet')}
            </LoadingButton>
          </ButtonContainer>
        </Grid>
      )}
    </>
  )
}

export default ConnectYourWallet
