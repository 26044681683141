import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Constants from '../utils/constants'
import { Select, MenuItem, Link, Box, BoxProps } from '@mui/material'

type Props = BoxProps

function LanguageSelect({ sx, ...props }: Props) {
  const { t, i18n } = useTranslation()

  const changeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language)
    },
    [i18n]
  )

  // when language is detected via browser, it's in {language}-{region} (en-US) format
  // we only need the language portion (en)
  const currentLanguage = i18n.language.substring(0, 2)
  console.log(currentLanguage)

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', alignItems: 'center' }} {...props}>
      <Select
        fullWidth
        defaultValue={currentLanguage}
        label={t(`language.${currentLanguage}`) as string}
        value={currentLanguage}
        variant="filled"
        disableUnderline
        sx={{ '& .MuiSelect-select': { padding: 2 } }}
      >
        {Constants.LANGUAGES.map((language: string) => (
          <MenuItem
            key={language}
            value={language}
            sx={{
              display: language === currentLanguage ? 'none' : 'block',
            }}
            disableRipple
            disableTouchRipple
          >
            <Link
              href="#"
              onClick={(event: any) => {
                event.preventDefault()
                changeLanguage(language)
              }}
              sx={{ textDecoration: 'none' }}
            >
              {t(`language.${language}`) as string}
            </Link>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default LanguageSelect
