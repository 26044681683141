import { useCallback } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import {
  Button,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
  FormHelperText,
} from '@mui/material'
import { responsiveTypography } from '../../utils/styles'
import Fees from './Fees'
import SpreedlyCardForm from './processor/Spreedly'
import UserDetailsForm from './UserDetailsForm'
import { useRecoilState, useRecoilValue } from 'recoil'
import { paymentAuthorizationState, paymentFormState, paymentStepState } from '../../store'
import { setIsAgreed } from '../../store/actions/payment/paymentForm'
import { PaymentProcessor } from '../../enum'
import AccountForm from './AccountForm'
import styled from '@emotion/styled'

const FCLabel = styled.div`
  font-size: 12px;
  line-height: 1.3;
`

interface CardFormProps {
  onSubmit: () => void
  price: string
  title: string
  accountId: string | undefined
}

function CardForm({ onSubmit, price, title, accountId }: CardFormProps) {
  const { t } = useTranslation()
  const [{ isFormValid, isSubmitting, processor, formError }, setPaymentState] = useRecoilState(paymentFormState)
  const { satoriFee, wyreFee, totalPrice } = useRecoilValue(paymentStepState)
  const handleChangeUserAgreement = useCallback(() => setIsAgreed(true, setPaymentState), [setPaymentState])
  const [{ isAuthorizing }] = useRecoilState(paymentAuthorizationState)

  let ProcessorForm
  switch (processor) {
    case PaymentProcessor.Spreedly:
    default:
      ProcessorForm = SpreedlyCardForm
  }

  return (
    <Grid container sx={{ mb: 2 }}>
      <Typography variant="h1" component="h1" sx={responsiveTypography(3)}>
        Payment Details
      </Typography>
      <Grid item container direction="row" justifyContent="space-between" spacing={3}>
        <AccountForm accountId={accountId} />
        <UserDetailsForm />
        <ProcessorForm />
        <Grid item>
          <FormHelperText error>{formError}</FormHelperText>
        </Grid>
      </Grid>
      <Fees price={price} title={title} satoriFee={satoriFee} wyreFee={wyreFee} totalPrice={totalPrice} />
      <FormGroup>
        <FormControlLabel
          id={'user-agreement'}
          control={<Checkbox onChange={handleChangeUserAgreement} />}
          label={
            <FCLabel>
              <Trans i18nKey="wyreAgreementAccept">
                I accept
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: '#4667E3',
                  }}
                  href={`https://www.sendwyre.com/legal/user-agreement`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Wyre's User Agreement
                </a>
                and understand that Wyre Payments serves as agent of the payee to accept and transmit funds for the sale
                of NFTs.
              </Trans>
            </FCLabel>
          }
          sx={{ mb: 1, lineHeight: '1 !important' }}
        />
      </FormGroup>

      <Button
        disabled={!isFormValid || isSubmitting}
        variant="contained"
        color="secondary"
        onClick={onSubmit}
        sx={{ mt: 3 }}
      >
        Submit
      </Button>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 1000,
        }}
        open={isSubmitting || isAuthorizing}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{}}>
          <CircularProgress color="inherit" />
          <Typography
            variant="h2"
            component="h1"
            color={'#fff'}
            sx={{ ...responsiveTypography(3), textAlign: 'center', mt: 3 }}
          >
            {t('orderLoading', { action: isSubmitting ? t('submitting') : t('authorizing') })}
          </Typography>
        </Grid>
      </Backdrop>
    </Grid>
  )
}

export default CardForm
